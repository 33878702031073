import { sendCustomerFeedbackToGoogleChat } from "../api/cloudFunctions";
import { uploadImagesToStorageBucket } from "../api/storeImages";
import { useFeedbackContext } from "./FeedbackContext";
import { FeedbackPopover } from "./FeedbackPopover";

export function BugReportPopover({ open, setOpen, anchorRef }) {
  // Context state
  const { bugReportInputText, setBugReportInputText, bugReportInputImages, setBugReportInputImages } =
    useFeedbackContext();

  // Send bug report to google chat on submit
  const handleSubmit = async () => {
    const imageUrls = await uploadImagesToStorageBucket(bugReportInputImages);
    sendCustomerFeedbackToGoogleChat({
      feedback_type: "bug_report",
      customer_feedback: bugReportInputText,
      image_urls: imageUrls,
    });
  };
  return (
    <FeedbackPopover
      headerText={"Did you find a bug? Let us know so we can fix it!"}
      thankYouText={"Thank you for reporting the bug!"}
      open={open}
      setOpen={setOpen}
      userInput={bugReportInputText}
      setUserInput={setBugReportInputText}
      handleSubmit={handleSubmit}
      imageButtonText={"Add Screenshots"}
      images={bugReportInputImages}
      setImages={setBugReportInputImages}
    />
  );
}

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AppProvider } from "components/appContext/AppContext";
import ReactDOM from "react-dom/client";
import App from "./App";
import theme from "./styles/theme";

// Render the App component to the root element in the index.html file
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AppProvider>
      <App />
    </AppProvider>
  </ThemeProvider>,
);

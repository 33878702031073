import { Content, fetchOneEntry, isPreviewing } from "@builder.io/sdk-react";
import { useEffect, useState } from "react";

export function BuilderPage({ customComponents = null }) {
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);

  useEffect(() => {
    // Get the page content from Builder
    fetchOneEntry({
      model: "page",
      apiKey: process.env.REACT_APP_BUILDER_API_KEY,
      userAttributes: {
        urlPath: window.location.pathname,
      },
    }).then((content) => {
      if (content) {
        setContent(content);
      }
      setNotFound(!content);
    });
  }, []);

  return notFound && !isPreviewing() ? (
    <div>404</div>
  ) : (
    <Content
      content={content}
      model="page"
      apiKey={process.env.REACT_APP_BUILDER_API_KEY}
      customComponents={customComponents}
    />
  );
}

import { createContext, useContext, useState } from "react";

const IdeationContext = createContext();

export function IdeationProvider({ children }) {
  // Local state

  // Summary of the video for which concepts should be generated
  const [videoSummary, setVideoSummary] = useState("");

  // Text generation model that should be used for generating the text of the concepts
  const [textModelSetting, setTextModelSetting] = useState("dummy");

  // Image generation model that should be used for genersating images for a specific concept
  const [imageModelSetting, setImageModelSetting] = useState("Dummy");

  // Number of concepts that should initially be generated
  const [nrConceptsSetting, setNrConceptsSetting] = useState(3);

  // List of generated concepts
  const [concepts, setConcepts] = useState([]);

  return (
    <IdeationContext.Provider
      value={{
        videoSummary,
        setVideoSummary,
        textModelSetting,
        setTextModelSetting,
        imageModelSetting,
        setImageModelSetting,
        nrConceptsSetting,
        setNrConceptsSetting,
        concepts,
        setConcepts,
      }}
    >
      {children}
    </IdeationContext.Provider>
  );
}

export function useIdeationContext() {
  return useContext(IdeationContext);
}

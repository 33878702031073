import LZString from "lz-string";
import {
  DEFAULT_USER_FILTER_VALUES,
  USER_FILTER_URL_ENCODING_MAPPING,
  USER_FILTER_VALUES,
} from "../api/typesense/filters";

export function convertFiltersToUrlEncodedString(selectedFilters) {
  let urlString = "";
  for (const [filterKey, defaultValue] of Object.entries(DEFAULT_USER_FILTER_VALUES)) {
    // Only save filters that exist and are not the default value
    if (filterKey in selectedFilters && selectedFilters[filterKey] !== defaultValue) {
      // To save characters only save the position inside the filter values and not the filter value itself
      if (filterKey in USER_FILTER_VALUES) {
        const allowedFilterValues = Object.keys(USER_FILTER_VALUES[filterKey].values);
        const position = allowedFilterValues.indexOf(selectedFilters[filterKey]);
        // Only save the filter if the filter value exist insdide the allowed filter values
        if (position !== -1) {
          urlString += `${USER_FILTER_URL_ENCODING_MAPPING[filterKey]}=${position}&`;
        }
      }
    }
  }
  // Only encode if at least one filter was set
  if (urlString.length > 0) urlString = LZString.compressToEncodedURIComponent(urlString);
  return urlString;
}

export function decodeFiltersFromUrlEncodedString(encodedString) {
  // Decode
  const decodedString = LZString.decompressFromEncodedURIComponent(encodedString);

  // Prefill filters with default values
  const filters = { ...DEFAULT_USER_FILTER_VALUES };

  // Split into seperate filters
  const urlFilters = decodedString.split("&");

  // Iterate over each filter included in the URL
  for (const urlFilter of urlFilters) {
    // Get the short filter key and the position of the value
    const [urlFilterKeyShort, urlFilterPosition] = urlFilter.split("=");

    // Convert the short filter key to the long form using the mapping table
    const urlFilterKeyLong = Object.keys(USER_FILTER_URL_ENCODING_MAPPING).find(
      (k) => USER_FILTER_URL_ENCODING_MAPPING[k] === urlFilterKeyShort,
    );

    // If a long filter key exists in the mapping table
    if (urlFilterKeyLong) {
      // Get the corresponding filter value for the respective filter position
      const allowedFilterValues = Object.keys(USER_FILTER_VALUES[urlFilterKeyLong].values);
      const filterValue = allowedFilterValues[urlFilterPosition];

      // If the position corresponds to a valid filter value, replace the default filter value with it
      if (filterValue) {
        filters[urlFilterKeyLong] = filterValue;
      }
    }
  }

  return filters;
}

export function updateUrlParameters(navigate, query, filterUrlString, setLastOutliersSearchParameters) {
  let urlParameters = "?";
  if (query && query.trim() !== "") {
    urlParameters += `search_query=${encodeURIComponent(query)}`;
  }
  if (filterUrlString.length > 0) {
    if (urlParameters.length > 1) {
      urlParameters += "&";
    }
    urlParameters += `filters=${filterUrlString}`;
  }
  navigate(urlParameters);
  setLastOutliersSearchParameters(urlParameters);
}

import { sendCustomerFeedbackToGoogleChat } from "../api/cloudFunctions";
import { useFeedbackContext } from "./FeedbackContext";
import { FeedbackPopover } from "./FeedbackPopover";

export function GeneralFeedbackPopover({ open, setOpen, anchorRef }) {
  // Context state
  const { generalFeedbackInputText, setGeneralFeedbackInputText } = useFeedbackContext();

  // Send feedback to google chat on submit
  const handleSubmit = () => {
    sendCustomerFeedbackToGoogleChat({
      feedback_type: "general_feedback",
      customer_feedback: generalFeedbackInputText,
      image_urls: [],
    });
  };
  return (
    <FeedbackPopover
      headerText={"Do you have feedback? We would love to hear it!"}
      thankYouText={"Thank you for your feedback!"}
      open={open}
      setOpen={setOpen}
      userInput={generalFeedbackInputText}
      setUserInput={setGeneralFeedbackInputText}
      handleSubmit={handleSubmit}
      anchorRef={anchorRef}
    />
  );
}

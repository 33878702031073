import { convertFiltersToUrlEncodedString, decodeFiltersFromUrlEncodedString } from "../../utils/url";
import { DEFAULT_USER_FILTER_VALUES } from "./filters";
import { triggerRandomSearch } from "./randomSearch";
import { triggerRegularSearch } from "./regularSearch";

// Trigger a search in Typesense based on the URL
export async function triggerUrlBasedSearch(setQuery, setSelectedFilters, setSelectedFiltersUrl, setIndexUiState) {
  // Get search query and filters from URL
  const urlParams = new URLSearchParams(window.location.search);
  const urlSearchQueryEncoded = urlParams.get("search_query");
  const urlFiltersEndcoded = urlParams.get("filters");

  // Decode the search query
  let urlSearchQueryDecoded = null;
  if (urlSearchQueryEncoded) {
    urlSearchQueryDecoded = decodeURIComponent(urlSearchQueryEncoded);
    // Make sure that it is not empty
    if (urlSearchQueryDecoded && urlSearchQueryDecoded.trim() !== "") {
      setQuery(urlSearchQueryDecoded);
    } else {
      urlSearchQueryDecoded = null;
    }
  }

  // Decode the filters
  let urlFiltersDecoded = null;
  if (urlFiltersEndcoded) {
    urlFiltersDecoded = decodeFiltersFromUrlEncodedString(urlFiltersEndcoded);
    setSelectedFilters(urlFiltersDecoded);
    setSelectedFiltersUrl(convertFiltersToUrlEncodedString(urlFiltersDecoded));
  }

  // If the URL includes only a search query, execute it with the default filters
  if (urlSearchQueryDecoded && !urlFiltersDecoded) {
    triggerRegularSearch(setIndexUiState, urlSearchQueryDecoded, DEFAULT_USER_FILTER_VALUES);

    // If the URL includes a search query and filters, execute the search with the filters
  } else if (urlSearchQueryDecoded && urlFiltersDecoded) {
    triggerRegularSearch(setIndexUiState, urlSearchQueryDecoded, urlFiltersDecoded);

    // Otherwise execute default search
  } else {
    triggerRandomSearch(setIndexUiState);
  }
}

import { Grid } from "@mui/material";
import { videoSummaryToConcepts } from "../api/cloudFunctions";
import { AddConceptPlaceholder } from "./AddConceptPlaceholder";
import { Concept } from "./Concept";
import { useIdeationContext } from "./IdeationContext";

export function ConceptGrid() {
  // Context state
  const { videoSummary, textModelSetting, concepts, setConcepts } = useIdeationContext();

  // Generate additional thumbnail concepts
  const handleClick = async () => {
    setConcepts((prev) => [...prev, {}]);
    try {
      const response = await videoSummaryToConcepts({
        video_summary: videoSummary,
        model: textModelSetting,
        nr_concepts_to_generate: 1,
        concepts_to_exclude: JSON.stringify(concepts),
      });
      setConcepts((prev) => [...prev.slice(0, prev.length - 1), ...response.data.concepts]);
    } catch (error) {
      console.log(error);
      setConcepts((prev) => prev.slice(0, prev.length - 1));
    }
  };

  return (
    <Grid container spacing={3} sx={{ alignItems: "center", justifyContent: "center" }}>
      {/* Display all available concepts */}
      {concepts.map((concept, index) => (
        <Grid item mobile={12} key={index}>
          <Concept concept={concept} />
        </Grid>
      ))}

      {/* Display an option to add a new concept */}
      {concepts.length > 0 && (
        <Grid item mobile={12}>
          <AddConceptPlaceholder
            handleClick={handleClick}
            disabled={concepts[concepts.length - 1].description ? false : true}
          />
        </Grid>
      )}
    </Grid>
  );
}

import { AppBarTuneButton } from "components/appBar/AppBarTuneButton";
import { useState } from "react";
import { SettingsPopup } from "./SettingsPopup";

export function SettingsButton() {
  // Local state
  const [open, setOpen] = useState(false);

  // Handle popup open and close
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBarTuneButton buttonText="Settings" handleOpen={handleOpen} />
      <SettingsPopup open={open} handleClose={handleClose} />
    </>
  );
}

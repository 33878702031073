import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import TextsmsIcon from "@mui/icons-material/Textsms";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { useAppContext } from "components/appContext/AppContext";
import { DrawerListItem } from "./DrawerListItem";
import { ResponsiveDrawer } from "./ResponsiveDrawer";

export function ApplicationDrawer({ userState }) {
  // App context
  const { lastOutliersSearchParameters } = useAppContext();

  // Auth
  const isDeveloper = userState.developer;

  return (
    <ResponsiveDrawer>
      <DrawerListItem
        longPageName="Outlier Database"
        shortPageName="Outliers"
        linkToPagePathname={"/app/outliers"}
        linkToPageParameters={lastOutliersSearchParameters}
        filledIcon={<SubscriptionsIcon />}
        outlienedIcon={<SubscriptionsOutlinedIcon />}
        userState={userState}
        authCheck={false}
      />
      <DrawerListItem
        longPageName="Favorites"
        shortPageName="Favorites"
        linkToPagePathname="/app/favorites"
        filledIcon={<FavoriteIcon />}
        outlienedIcon={<FavoriteBorderIcon />}
        userState={userState}
        authCheck={true}
      />
      {isDeveloper && (
        <>
          <DrawerListItem
            longPageName="Prompting"
            shortPageName="Prompting"
            linkToPagePathname="/app/prompt"
            filledIcon={<TextsmsIcon />}
            outlienedIcon={<TextsmsOutlinedIcon />}
          />
          <DrawerListItem
            longPageName="Ideation"
            shortPageName="Ideation"
            linkToPagePathname="/app/ideation"
            filledIcon={<TipsAndUpdatesIcon />}
            outlienedIcon={<TipsAndUpdatesOutlinedIcon />}
          />
        </>
      )}
    </ResponsiveDrawer>
  );
}

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, IconButton } from "@mui/material";
import theme from "styles/theme";

export function AddConceptPlaceholder({ handleClick, disabled }) {
  return (
    <Box
      sx={{
        borderRadius: 5,
        borderWidth: 1,
        borderColor: theme.palette.grey.main,
        borderStyle: "solid",
        p: 2,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <IconButton onClick={handleClick} disabled={disabled}>
        <AddCircleOutlineIcon
          sx={{ color: disabled ? theme.palette.grey.main : theme.palette.primary.main, width: 40, height: 40 }}
        />
      </IconButton>
    </Box>
  );
}

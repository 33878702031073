import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import theme from "styles/theme";

export function PopupWithCloseButton({ open, handleClose, title, children }) {
  return (
    <Modal open={open} onClose={handleClose} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box
        sx={{
          position: "relative",
          backgroundColor: theme.palette.white.main,
          borderRadius: "20px",
          boxShadow: 5,
          maxWidth: "420px",
          justifyContent: "left",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          padding: "20px",
          margin: 2,
        }}
      >
        {/* Close icon in top right corner */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.black.main,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" sx={{ mb: 2 }}>
          {title}
        </Typography>

        {children}
      </Box>
    </Modal>
  );
}

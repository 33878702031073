import { Box } from "@mui/material";
import { FeedbackSpeedDial } from "features/feedback";
import { CustomHelmet } from "features/helmet";
import { ApplicationAppBar, ApplicationDrawer } from "features/navigation";
import { PromptInput, PromptProvider, ThumbnailGrid } from "features/prompting";
import { mainBoxStyles, outerBoxStyles } from "styles/layout";

export default function PromptPage({ userState }) {
  return (
    <>
      <CustomHelmet index={false} follow={false} />
      <PromptProvider>
        <Box {...outerBoxStyles}>
          <ApplicationAppBar userState={userState}>
            <PromptInput />
          </ApplicationAppBar>
          <ApplicationDrawer userState={userState} />
          <FeedbackSpeedDial />
          <Box {...mainBoxStyles}>
            <ThumbnailGrid />
          </Box>
        </Box>
      </PromptProvider>
    </>
  );
}

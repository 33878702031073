import { auth, db } from "config/firebase";
import { collection, doc, getDocs, orderBy, query } from "firebase/firestore";

// Function to fetch generated thumbnails of logged-in user from firestore
export async function fetchGeneratedThumbnailsFromFirestore() {
  // TODO: Check if user exisits
  // TODO: Handle errors if user hasn't generated any thumbnails
  const userDocRef = doc(db, "thumbnail-generation-logs", auth.currentUser.email);
  const thumbnailsCollectionRef = collection(userDocRef, "generated-thumbnails");
  const sortedQuery = query(thumbnailsCollectionRef, orderBy("creation_time", "desc"));
  const querySnapshot = await getDocs(sortedQuery);
  const queryData = querySnapshot.docs.map((doc) => doc.data());
  return queryData;
}

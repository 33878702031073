export function formatDate(timestamp) {
  const date = new Date(timestamp);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function calculateReadTime(blocks) {
  if (!blocks) {
    return 0;
  }
  const wordCount = blocks.reduce((acc, block) => {
    if (block.component && block.component.options && block.component.options.text) {
      // Extract text and count words
      const text = block.component.options.text.replace(/<[^>]*>/g, ""); // Remove HTML tags
      return acc + text.split(" ").length;
    }
    return acc;
  }, 0);

  return Math.ceil(wordCount / 275); // Assuming average reading speed of 275 words per minute
}

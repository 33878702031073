import { Helmet } from "react-helmet";

export function CustomHelmet({
  title = "glowAI",
  description = "Discover the Best-Performing Videos on YouTube. Get Thumbnail and Title Inspirations. Find New Viral YouTube Video Ideas.",
  image = "/favicon-196x196.png",
  url = null,
  type = "website",
  index = true,
  follow = true,
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url === null ? window.location.href : url} />
      <meta property="og:type" content={type} />
      <meta name="robots" content={`${index ? "index" : "noindex"}, ${follow ? "follow" : "nofollow"}`} />
      <meta name="twitter:card" content={"summary_large_image"} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content={url === null ? window.location.href : url} />
    </Helmet>
  );
}

import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import theme from "styles/theme";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

const textFieldStyle = {
  mr: 1,
  "& .MuiOutlinedInput-root": {
    height: "30px", // Setzt die Höhe des Rahmens
  },
  "& .MuiOutlinedInput-input": {
    height: "30px", // Setzt die Höhe des inneren Eingabeelements
    padding: "0px 14px", // Passt das Padding an, um den Text vertikal zu zentrieren
  },
  "& .MuiInputLabel-outlined": {
    lineHeight: "30px", // Stellt sicher, dass das Label die gleiche Höhe wie der Input hat
    top: -10, // Setzt das Label an den oberen Rand des Input-Felds
    left: 0, // Setzt das Label an den linken Rand des Input-Felds
    // Passt die Standardposition des Labels an, um es innerhalb des Rahmens zu zentrieren
    transform: "translate(14px, 10px) scale(1)", // Feinjustierung für die Zentrierung
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)", // Passt die Position des geschrumpften Labels an
  },
};

export function TypesenseManager({ props, setIndexName, setTypesenseInstance, setManualIndexInput }) {
  const [host, setHost] = useState("");
  const [collectionName, setCollectionName] = useState(process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION);
  const [readKey, setReadKey] = useState("");

  function updateTypesenseInstance() {
    setManualIndexInput(true);
    setTypesenseInstance(
      new TypesenseInstantSearchAdapter({
        server: {
          nodes: [
            {
              host: host,
              port: "443",
              protocol: "https",
            },
          ],
          apiKey: readKey,
        },
        additionalSearchParameters: {
          collection: collectionName,
          query_by: "embedding,title,channel_title,search_terms,custom_url",
          include_fields: process.env.REACT_APP_TYPESENSE_INCLUDE_FIELDS,
          //infix: "off,always, off, off, always",
          highlight_fields: "none",
          highlight_full_fields: "none",
          split_join_tokens: "always",
          drop_tokens_threshold: 0,
        },
      }),
    );

    setTimeout(() => {
      setIndexName(collectionName);
    }, 2000);
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 1,
        mb: 2,
        backgroundColor: theme.palette.grey.light,
        borderRadius: 3,
        height: 40,
      }}
    >
      <TextField
        label="Host"
        variant="outlined"
        size="small"
        value={host}
        onChange={(e) => setHost(e.target.value)}
        sx={textFieldStyle}
      />
      <TextField
        label="Collection Name"
        variant="outlined"
        size="small"
        value={collectionName}
        onChange={(e) => setCollectionName(e.target.value)}
        sx={textFieldStyle}
      />
      <TextField
        label="Read Key"
        variant="outlined"
        size="small"
        value={readKey}
        onChange={(e) => setReadKey(e.target.value)}
        sx={textFieldStyle}
      />
      <Button
        onClick={updateTypesenseInstance}
        variant="contained"
        color="primary"
        sx={{
          height: "30px",
          "& .MuiButton-root": {
            height: "30px",
          },
        }}
      >
        Update
      </Button>
    </Box>
  );
}

import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { PerformanceScoreChip } from "components/performanceScore/PerformanceScoreChip";
import { useEffect, useState } from "react";
import { useIsMobile, useIsTablet } from "styles/breakpoint";
import { getRandomItems, thumbnailCollection } from "../utils/thumbnailCollection";
import { ThumbnailPreview } from "./ThumbnailPreview";

export function ThumbnailGrid() {
  // Local state
  const [thumbnailsToDisplay, setThumbnailsToDisplay] = useState([]);

  // Breakpoint
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  // Select random thumbnails from collection
  useEffect(() => {
    // Number of thumbnails to display
    var thumbnailCount = 15;
    if (isTablet) {
      thumbnailCount = 10;
    } else if (isMobile) {
      thumbnailCount = 5;
    }

    setThumbnailsToDisplay(getRandomItems(thumbnailCollection, thumbnailCount));
  }, [isTablet, isMobile]);

  return (
    <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
      {thumbnailsToDisplay.map((thumbnail, index) => (
        <Grid mobile={12} tablet={6} smallDesktop={4} key={index}>
          <Stack
            direction={"column"}
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <ThumbnailPreview videoId={thumbnail.videoId} />
            <PerformanceScoreChip score={thumbnail.score} />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}

import { AppBar, Avatar, Box, Button, Toolbar, Typography } from "@mui/material";
import LogoBlack from "assets/logos/glowAI_icon_black_and_logo_black.svg";
import LogoWhite from "assets/logos/glowAI_icon_white_and_logo_white.svg";
import { SignInButton } from "components/appBar/SignInButton";
import { auth } from "config/firebase";
import { Link, useNavigate } from "react-router-dom";
import { useIsDesktop, useIsTablet } from "styles/breakpoint";
import { landingContentStyles, MAX_SECTION_WIDTH } from "styles/layout";
import theme from "styles/theme";
import "../styles/appBarLanding.css";

export function LandingAppBar({ componentBackground }) {
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();

  const isWhiteBackground = componentBackground === "white";

  // Change design depending on background
  const textColor = isWhiteBackground ? theme.palette.black.main : theme.palette.white.main;
  const Logo = isWhiteBackground ? LogoBlack : LogoWhite;
  const betaIconBackgroundColor = isWhiteBackground ? "rgba(48, 111, 219, 0.9)" : "rgba(48, 111, 219, 1)";
  const buttonBackgroundColor = isWhiteBackground ? "rgba(48, 111, 219, 1)" : theme.palette.white.main;
  const buttonBackgroundColorHover = isWhiteBackground ? "rgba(48, 111, 219, 0.8)" : "rgba(255, 255, 255, 0.8)";
  const buttonFontColor = isWhiteBackground ? theme.palette.white.main : "rgba(90, 185, 245, 1)";

  // Check if user is authenticated
  const isAuthenticated = auth.currentUser ? true : false;

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        flexGrow: 1,
        justifyContent: "center",
        display: "flex",
        bgcolor: isWhiteBackground ? theme.palette.white.main : "transparent",
        pb: isWhiteBackground ? 4 : 0,
      }}
    >
      <AppBar
        position={isWhiteBackground ? "fixed" : "static"}
        sx={{
          bgcolor: isWhiteBackground ? theme.palette.white.main : "transparent",
          boxShadow: "none",
          width: "100%",
          maxWidth: MAX_SECTION_WIDTH,
          margin: "auto",
          left: 0,
          right: 0,
          ...landingContentStyles.sx,
        }}
      >
        <Toolbar sx={{ alignItems: "center", justifyContent: "space-between", width: "100%", p: 0 }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <Link to="/" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Box
                component={"img"}
                src={Logo}
                alt="glowAI Logo"
                sx={{ height: "25px", display: "flex", alignItems: "center", justifyContent: "center" }}
              />
            </Link>
            {(isTablet || isDesktop) && (
              <Box
                sx={{
                  backgroundColor: betaIconBackgroundColor,
                  borderRadius: 20,
                  marginLeft: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="custom" sx={{ marginLeft: "10px", marginRight: "10px", fontSize: 12 }}>
                  Beta
                </Typography>
              </Box>
            )}
            {isDesktop && (
              <Box sx={{ marginLeft: "40px" }}>
                <Link to="/#search-section">
                  <Button
                    sx={{
                      textTransform: "none",
                      color: textColor,
                      marginLeft: 2,
                      marginRight: 2,
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Typography variant="body2" component="div">
                      Home
                    </Typography>
                  </Button>
                </Link>
                {!isWhiteBackground && (
                  <a href="#faq-section">
                    <Button
                      sx={{
                        textTransform: "none",
                        color: textColor,
                        marginLeft: 2,
                        marginRight: 2,
                        "&:hover": { backgroundColor: "transparent" },
                      }}
                    >
                      <Typography variant="body2" component="div">
                        FAQs
                      </Typography>
                    </Button>
                  </a>
                )}
                <Button
                  sx={{
                    textTransform: "none",
                    color: textColor,
                    marginLeft: 2,
                    marginRight: 2,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  onClick={() => navigate("/blog")}
                >
                  <Typography variant="body2" component="div">
                    Blog
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>
          {isAuthenticated ? (
            <Avatar
              onClick={() => navigate("/app/outliers")}
              sx={{
                textTransform: "none",
                height: { mobile: 30, tablet: 40 },
                width: { mobile: 30, tablet: 40 },
                fontSize: { mobile: 12, tablet: 18 },
                boxShadow: 0,
                color: buttonFontColor,
                bgcolor: buttonBackgroundColor,
                cursor: "pointer",
                transition: "box-shadow 0.3s ease-in-out",
                "&:hover": {
                  bgcolor: buttonBackgroundColorHover,
                  border: 0,
                },
              }}
            >
              {auth.currentUser.displayName[0]}
            </Avatar>
          ) : (
            <SignInButton isWhiteBackground={isWhiteBackground} />
          )}
        </Toolbar>
      </AppBar>
      {isWhiteBackground && <Box sx={{ paddingTop: "56px" }}></Box>}
    </Box>
  );
}

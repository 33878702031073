import { Stack } from "@mui/material";
import { stackStyles } from "styles/appBarInput";
import { PromptTextField } from "./PromptTextField";
import { SettingsButton } from "./SettingsButton";

export function PromptInput() {
  return (
    <Stack {...stackStyles}>
      <PromptTextField />
      <SettingsButton />
    </Stack>
  );
}

import { keyframes } from "@emotion/react";
import { LinearProgress } from "@mui/material";
import theme from "styles/theme";

// Define a keyframe animation for the LinearProgress
const progressAnimation = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 100% 0; }
`;

// Custom styled LinearProgress component
export function FancyLinearProgress({ variant, value }) {
  return (
    <LinearProgress
      variant={variant}
      value={value}
      sx={{
        height: 8,
        mb: 2,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[150],
        "& .MuiLinearProgress-bar": {
          borderRadius: 5,
          backgroundImage: `linear-gradient(270deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          animation: `${progressAnimation} 1.5s linear infinite`,
        },
      }}
    />
  );
}

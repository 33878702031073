import theme from "styles/theme";

export const stackStyles = {
  direction: "row",
  spacing: 1,
  sx: { width: 600, alignItems: "center" },
};

export const textFieldStyles = {
  size: "small",
  sx: {
    width: "100%",
    paddingLeft: 1,
    paddingRight: 1,
    "& .MuiInputBase-input::placeholder": {
      ...theme.typography.body2,
    },
    "& .MuiInputBase-input": {
      ...theme.typography.body2,
    },
  },
  InputProps: {
    sx: {
      borderRadius: 10,
      fontSize: 16,
    },
  },
};

import { Divider, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";

export function SingleSelectColumn({ title, availableOptions, selectedOption, setSelectedOption }) {
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2">{title}</Typography>
      <Divider />
      <RadioGroup value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
        {availableOptions.map((option, index) => (
          <FormControlLabel
            value={option}
            control={<Radio icon={<span />} checkedIcon={<span />} sx={{ p: 0 }} />}
            label={
              <Typography variant="body3" fontWeight={selectedOption === option ? "bold" : "normal"}>
                {option}
              </Typography>
            }
            sx={{ padding: 0, m: 0, mb: 1 }}
            key={index}
          />
        ))}
      </RadioGroup>
    </Stack>
  );
}

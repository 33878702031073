export async function downloadThumbnailToLocalDrive(thumbnailUrl) {
  try {
    const response = await fetch(thumbnailUrl, { mode: "cors" });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "glowAI Thumbnail.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Fetch Error:", error);
  }
}

import { analytics, auth, db, logEvent } from "config/firebase";
import { Timestamp, deleteField, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export async function getChannelBlacklist() {
  try {
    // check if there is a channel-blacklist document in the channel-blacklist collection
    const docRef = doc(db, "outlier-frontend", "channel-blacklist");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // return the list of blacklisted channels
      return docSnap.data().channelNames;
    } else {
      // return an empty array if the document does not exist
      return null;
    }
  } catch (error) {
    // return an empty array if there is an error
    console.error(error);
    return null;
  }
}

async function getFavoritesDataFromFirestore() {
  if (!auth.currentUser || !auth.currentUser.email) throw new Error("User is not authenticated");
  try {
    // Check if there exists a favorites collection for the logged in user
    const docRef = doc(db, "favorite-thumbnail-outliers", auth.currentUser.email, "collections", "default");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data;
    } else {
      // Return an empty object if the document does not exist
      return {};
    }
  } catch (error) {
    // Return an empty object if there is an error
    return {};
  }
}

export async function getSortedFavoritesDataFromFirestore() {
  const unsortedFavoritesData = await getFavoritesDataFromFirestore();

  // Convert to [key, value] pairs and sort by the time it was saved in decending order
  const sortedFavoritesArray = Object.entries(unsortedFavoritesData).sort((a, b) => {
    return b[1].save_datetime - a[1].save_datetime;
  });

  // Convert back to object
  const sortedFavoritesData = Object.fromEntries(sortedFavoritesArray);

  return sortedFavoritesData;
}

export async function getFavoritesVideoIdsFromFirestore() {
  const favoritesData = await getFavoritesDataFromFirestore();
  // Only return the video IDs (unsorted)
  return Object.keys(favoritesData);
}

export async function addVideoToFirestoreFavorites(data) {
  if (!auth.currentUser || !auth.currentUser.email) throw new Error("User is not authenticated");
  try {
    // Get reference to user's default favorite collection
    const docRef = doc(db, "favorite-thumbnail-outliers", auth.currentUser.email, "collections", "default");
    const docSnap = await getDoc(docRef);

    // Add a new key with the video ID that should be saved to favorites and a value of the video data (this is required
    // as it takes some time for new results from live search to be stored in typesense)
    const updates = {
      [data.id]: {
        save_datetime: Timestamp.fromDate(new Date()),
        id: "id" in data ? data.id : null,
        title: "title" in data ? data.title : null,
        channel_title: "channel_title" in data ? data.channel_title : null,
        custom_url: "custom_url" in data ? data.custom_url : null,
        duration: "duration" in data ? data.duration : null,
        med_view_score: "med_view_score" in data ? data.med_view_score : null,
        view_count: "view_count" in data ? data.view_count : null,
        channel_med_views: "channel_med_views" in data ? data.channel_med_views : null,
        published_at: "published_at" in data ? data.published_at : null,
        subscriber_count: "subscriber_count" in data ? data.subscriber_count : null,
      },
    };

    if (!docSnap.exists()) {
      // Create new doc if a document doesn't exist
      await setDoc(docRef, updates);
    } else {
      // Otherwise update the existing one
      await updateDoc(docRef, updates);
    }

    // Log event
    logEvent(analytics, "added_video_to_favorites", {
      video_id: data.id,
    });
  } catch (error) {
    console.log(error);
    throw new Error("Failed to add video to favorites in Firestore");
  }
}

export async function removeVideoFromFirestoreFavorites(videoId) {
  if (!auth.currentUser || !auth.currentUser.email) throw new Error("User is not authenticated");
  try {
    // Get reference to user's default favorite collection
    const docRef = doc(db, "favorite-thumbnail-outliers", auth.currentUser.email, "collections", "default");

    // Remove document entry of video id
    const updates = {
      [videoId]: deleteField(),
    };

    // Update the firestore doc
    await updateDoc(docRef, updates);

    // Log event
    logEvent(analytics, "removed_video_from_favorites", {
      video_id: videoId,
    });
  } catch (error) {
    throw new Error("Failed to remove video from favorites in Firestore");
  }
}

import { createContext, useContext, useState } from "react";

const PromptContext = createContext();

export function PromptProvider({ children }) {
  // Local state
  const [modelSetting, setModelSetting] = useState("Dummy");
  const [page, setPage] = useState(1);
  const [thumbnailGrid, setThumbnailGrid] = useState({ loadingCount: 0, thumbnails: [] });

  // Local state update functions
  const incrementLoadingCount = () => {
    setThumbnailGrid((prev) => ({ ...prev, loadingCount: prev.loadingCount + 1 }));
  };

  const decrementLoadingCount = () => {
    setThumbnailGrid((prev) => ({ ...prev, loadingCount: prev.loadingCount - 1 }));
  };

  return (
    <PromptContext.Provider
      value={{
        modelSetting,
        setModelSetting,
        page,
        setPage,
        thumbnailGrid,
        setThumbnailGrid,
        incrementLoadingCount,
        decrementLoadingCount,
      }}
    >
      {children}
    </PromptContext.Provider>
  );
}

export function usePromptContext() {
  return useContext(PromptContext);
}

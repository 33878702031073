import Grid from "@mui/material/Unstable_Grid2";

export function ImageGrid({ children, columnsMobile = null, columnsTablet = null }) {
  const childrenCount = children ? children.length : 0;

  const finalColumnsMobile = columnsMobile ? columnsMobile : Math.min(childrenCount, 1);
  const finalColumnsTablet = columnsTablet ? columnsTablet : Math.min(childrenCount, 3);

  return (
    <Grid
      container
      spacing={1}
      columns={60}
      sx={{ minHeight: "100px", width: "100%", justifyContent: "center", display: "flex" }}
    >
      {children &&
        children.map((child, index) => (
          <Grid mobile={60 / finalColumnsMobile} tablet={60 / finalColumnsTablet} key={index}>
            {child}
          </Grid>
        ))}
    </Grid>
  );
}

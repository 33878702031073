import { AppBarTuneButton } from "components/appBar/AppBarTuneButton";
import { useState } from "react";
import { FiltersPopup } from "./FiltersPopup";
import { useOutliersContext } from "./OutliersContext";

export function FiltersButton() {
  // Local state
  const [open, setOpen] = useState(false);

  // Context state
  const { query } = useOutliersContext();

  // Handle popup open and close
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Only show Filters Button if there is a query in the search bar */}
      {query && (
        <>
          <AppBarTuneButton buttonText="Filters" handleOpen={handleOpen} />
          <FiltersPopup open={open} handleClose={handleClose} />
        </>
      )}
    </>
  );
}

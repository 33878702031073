import ClearIcon from "@mui/icons-material/Clear";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, IconButton, Popover, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useIsMobile } from "styles/breakpoint";
import theme from "styles/theme";

export function FeedbackPopover({
  headerText,
  thankYouText,
  open,
  setOpen,
  userInput,
  setUserInput,
  handleSubmit,
  imageButtonText,
  images,
  setImages,
}) {
  // Local state
  const [submitted, setSubmitted] = useState(false);

  // Breakpoint
  const isMobile = useIsMobile();

  // Determine vertical anchor based on screen size
  const verticalAnchor = isMobile ? "top" : "bottom";

  // Local function to handle the feedback submit
  const localHandleSubmit = () => {
    setSubmitted(true);
    setUserInput("");
    if (images) {
      setImages([]);
    }
    handleSubmit();
  };

  // On submit display the thank you message for a couple of seconds then close the window automatically
  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        setOpen(false);
        setTimeout(() => setSubmitted(false), 500);
      }, 3000);
    }
  }, [submitted]);

  return (
    <Popover
      open={open}
      onClose={() => {
        setOpen(false);
        setTimeout(() => {
          setSubmitted(false);
        }, 500);
      }}
      disableScrollLock={true}
      PaperProps={{
        sx: {
          borderRadius: 4,
          width: { mobile: "100%", tablet: 300 },
          position: "fixed",
        },
      }}
      anchorOrigin={{
        vertical: verticalAnchor,
        horizontal: "left",
      }}
    >
      <Box sx={{ p: 2 }}>
        <Stack spacing={2} direction={"column"} sx={{ justifyContent: "center", alignItems: "center" }}>
          {!submitted ? (
            <>
              {/* Display text advice to the user */}
              <Typography variant="body2">{headerText}</Typography>

              {/* Display textfield where user can input feedback */}
              <TextField
                size="small"
                autoFocus
                multiline
                rows={3}
                sx={{ width: "100%" }}
                InputProps={{ sx: { borderRadius: 4 } }}
                onChange={(e) => setUserInput(e.target.value)}
                value={userInput}
              />

              {images && (
                <>
                  {/* Display uploaded images by user */}
                  <Grid container sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                    {images.map((image, index) => (
                      <Grid mobile={3} key={index} sx={{ display: "flex", justifyContent: "center" }}>
                        <Box position="relative" sx={{ width: 50, height: 50 }}>
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Uploaded ${index}`}
                            style={{ width: 50, height: 50, borderRadius: 4 }}
                          />
                          {/* An image can be deleted by clicking on the delete button right next to it */}
                          <IconButton
                            size="small"
                            sx={{
                              width: 20,
                              height: 20,
                              position: "absolute",
                              top: -10,
                              left: 40,
                              backgroundColor: theme.palette.grey.main,
                              ":hover": {
                                backgroundColor: theme.palette.grey.main,
                              },
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setImages((prevImages) => prevImages.filter((img, imgIndex) => imgIndex !== index));
                            }}
                          >
                            <ClearIcon
                              sx={{
                                width: 15,
                                height: 15,
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>

                  {/* Display button to upload images */}
                  <Box sx={{ display: "flex", justifyContent: "left", width: "100%" }}>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ borderRadius: 10, textTransform: "none" }}
                      disabled={images.length >= 4}
                    >
                      {imageButtonText}
                      <input
                        type="file"
                        hidden
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          setImages((prevImages) => [...prevImages, e.target.files[0]]);
                          e.target.value = null;
                        }}
                      />
                    </Button>
                  </Box>
                </>
              )}

              {/* Display submit button */}
              <Box sx={{ display: "flex", justifyContent: "right", width: "100%" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    localHandleSubmit();
                  }}
                  sx={{ width: 30, borderRadius: 10 }}
                  disabled={!userInput && images && !images.length}
                >
                  <SendIcon />
                </Button>
              </Box>
            </>
          ) : (
            <Box sx={{ height: 100, display: "flex", alignItems: "center" }}>
              {/* Display thank you message */}
              <Typography variant="body2">{thankYouText}</Typography>
            </Box>
          )}
        </Stack>
      </Box>
    </Popover>
  );
}

import { Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useIsDesktop, useIsMobile, useIsTablet } from "styles/breakpoint";
import theme from "styles/theme";

export function DrawerListItemContent({ longPageName, shortPageName, linkToPagePathname, filledIcon, outlienedIcon }) {
  // Url
  const location = useLocation();

  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  return isDesktop ? (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        padding: 1,
        borderRadius: 2,
        backgroundColor: location.pathname.startsWith(linkToPagePathname) ? theme.palette.selected.main : "inherit",
        "&:hover": {
          backgroundColor: theme.palette.selected.main,
        },
      }}
    >
      {location.pathname.startsWith(linkToPagePathname) ? filledIcon : outlienedIcon}
      <Typography variant="body2">{longPageName}</Typography>
    </Stack>
  ) : isTablet ? (
    <Stack
      direction="column"
      alignItems="center"
      spacing={0.5}
      sx={{
        padding: 1,
        borderRadius: 2,
        backgroundColor: location.pathname.startsWith(linkToPagePathname) ? theme.palette.selected.main : "inherit",
        "&:hover": {
          backgroundColor: theme.palette.selected.main,
        },
      }}
    >
      {location.pathname.startsWith(linkToPagePathname) ? filledIcon : outlienedIcon}
      <Typography variant="custom" sx={{ fontSize: 8 }}>
        {shortPageName}
      </Typography>
    </Stack>
  ) : (
    isMobile && (
      <Stack
        direction="column"
        alignItems="center"
        spacing={0.5}
        sx={{
          padding: 1,
          borderRadius: 2,
        }}
      >
        {location.pathname.startsWith(linkToPagePathname) ? filledIcon : outlienedIcon}
        <Typography variant="custom" sx={{ fontSize: 8 }}>
          {shortPageName}
        </Typography>
      </Stack>
    )
  );
}

import { HITS_PER_PAGE } from "./config";
import { createFilterString, MUST_HAVE_FILTER_STRING, USER_FILTER_VALUES } from "./filters";

// Trigger a regular search in Typesense
export function triggerRegularSearch(setIndexUiState, query, selectedFilters) {
  const filterString = createFilterString(selectedFilters);
  const sortSting =
    process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION + USER_FILTER_VALUES.sort_by.values[selectedFilters.sort_by];

  triggerRegularSearchRaw(setIndexUiState, query, filterString, sortSting);
}

// Trigger a regular search in Typesense from raw filter and sort strings
export function triggerRegularSearchRaw(setIndexUiState, query, filterString, sortString) {
  setIndexUiState({
    query: query,
    configure: {
      filters: filterString + (filterString ? " && " : "") + MUST_HAVE_FILTER_STRING,
      hitsPerPage: HITS_PER_PAGE,
    },
    page: 0,
    sortBy: sortString,
  });
}

// Search video ids in Typesense
export function triggerVideoIdSearch(setIndexUiState, videoIds) {
  const filterString = `id:[${videoIds.join(",")}]`;

  setIndexUiState({
    configure: {
      filters: filterString,
    },
  });
}

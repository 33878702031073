import Grid from "@mui/material/Unstable_Grid2";
import { SingleSelectColumn } from "components/appBar/SingleSelectColumn";
import { TunePopup } from "components/appBar/TunePopup";
import { useAppContext } from "components/appContext/AppContext";
import { analytics, logEvent } from "config/firebase";
import { useState } from "react";
import { useInstantSearch } from "react-instantsearch";
import { useNavigate } from "react-router-dom";
import { USER_FILTER_VALUES } from "../api/typesense/filters";
import { triggerRegularSearch } from "../api/typesense/regularSearch";
import { convertFiltersToUrlEncodedString, updateUrlParameters } from "../utils/url";
import { useOutliersContext } from "./OutliersContext";

export function FiltersPopup({ open, handleClose }) {
  // Search state
  const { setIndexUiState } = useInstantSearch();

  // Search context State
  const { selectedFilters, setSelectedFilters, query, setSelectedFiltersUrl } = useOutliersContext();

  // Local state
  const [selectedFiltersTemp, setSelectedFiltersTemp] = useState({ ...selectedFilters });

  // App context state
  const { setLastOutliersSearchParameters } = useAppContext();

  // Url
  const navigate = useNavigate();

  // Apply filters when pressing the apply button of the filters popup
  const handleApply = () => {
    // Update url
    const newSelectedUrlString = convertFiltersToUrlEncodedString(selectedFiltersTemp);
    setSelectedFiltersUrl(newSelectedUrlString);
    updateUrlParameters(navigate, query, newSelectedUrlString, setLastOutliersSearchParameters);

    // Log event
    logEvent(analytics, "apply_filters", {
      filters: selectedFiltersTemp,
    });

    // Execute new search with updated filters
    triggerRegularSearch(setIndexUiState, query, selectedFiltersTemp);

    // Set filters in context
    setSelectedFilters(selectedFiltersTemp);

    handleClose();
  };

  return (
    <TunePopup
      title="Filters"
      open={open}
      handleClose={() => {
        handleClose();
        setSelectedFiltersTemp(selectedFilters);
      }}
      handleApply={handleApply}
    >
      {/* Filters */}
      {Object.entries(USER_FILTER_VALUES).map(([category, options]) => (
        <Grid mobile={6} tablet={6} smallDesktop={4} key={category}>
          <SingleSelectColumn
            title={options.showName.replace("_", " ").toUpperCase()}
            availableOptions={Object.keys(options.values)}
            selectedOption={selectedFiltersTemp[category]}
            setSelectedOption={(value) => setSelectedFiltersTemp({ ...selectedFiltersTemp, [category]: value })}
          />
        </Grid>
      ))}
    </TunePopup>
  );
}

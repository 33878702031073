import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Box, Modal, Skeleton, Tooltip, Typography } from "@mui/material";
import { PerformanceScoreChip } from "components/performanceScore/PerformanceScoreChip";
import { analytics, logEvent } from "config/firebase";
import { SignInBox } from "features/authentication";
import { useRef, useState } from "react";
import { useIsMobile } from "styles/breakpoint";
import theme from "styles/theme";
import { thumbnailStyles } from "styles/thumbnail";
import { calculateVideoAge, convertVideoLength, formatScore } from "../utils/formatting";

export function VideoPreview({
  data,
  userState,
  favorite,
  enableFavoriteInteraction,
  addToFavorites,
  removeFromFavorites,
  fetchFavoritesFromFirestore,
  removeHitById,
}) {
  // Local state
  const [openAuthPopup, setOpenAuthPopup] = useState(false);
  const [favoriteTooltipText, setFavoriteTooltipText] = useState(
    favorite ? "Remove from favorites" : "Save to favorites",
  );
  const [thumbnailIsHovered, setThumbnailIsHovered] = useState(false);
  const [isNotFoundImage, setIsNotFoundImage] = useState(false);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;

    if (naturalWidth === 120) {
      setIsNotFoundImage(true);
      removeHitById(data.id);
    } else {
      setIsNotFoundImage(false);
    }
  };

  // Breakpoint
  const isMobile = useIsMobile();

  // Redirect to YouTube video when clicking on the thumbnail
  const redirectToYouTubeVideo = () => {
    logEvent(analytics, "click_video_preview", {
      videoId: data.id,
      videoTitle: data.title,
      channelTitle: data.channel_title,
    });
    window.open("https://www.youtube.com/watch?v=" + data.id, "_blank");
  };

  // Redirect to YouTube channel when clicking on the channel name
  const redirectToYouTubeChannel = () => {
    if (data.custom_url) window.open("https://www.youtube.com/" + data.custom_url, "_blank");
    else return;
  };

  // Ref to store the timeout ID for the favorite tooltip
  const favoriteTimeoutRef = useRef(null); // Ref to store the timeout ID

  // Add to or remove from favorites when clicking on the favorite icon (if the user is logged in)
  const setFavorite = () => {
    if (userState.authenticated) {
      if (favorite) {
        setFavoriteTooltipText(null);
        removeFromFavorites(data.id);
      } else {
        setFavoriteTooltipText(null);
        addToFavorites(data);
      }

      // Clear any existing timeout to prevent old tooltips from showing up
      if (favoriteTimeoutRef.current) {
        clearTimeout(favoriteTimeoutRef.current);
      }

      // Set a new timeout and store its ID in the ref
      favoriteTimeoutRef.current = setTimeout(() => {
        // Revert tooltip text back to original after 3 seconds
        setFavoriteTooltipText(favorite ? "Save to favorites" : "Remove from favorites");
      }, 3000);
    } else {
      setOpenAuthPopup(true);
    }
  };

  const handleClose = () => {
    setOpenAuthPopup(false);
  };

  return (
    !isNotFoundImage && (
      <>
        <Box>
          {/* Thumbnail with favorite icon in top right corner and video duration in bottom right corner */}
          <Box
            position={"relative"}
            onMouseEnter={() => setThumbnailIsHovered(true)}
            onMouseLeave={() => setThumbnailIsHovered(false)}
            sx={{
              ...(!isMobile && {
                transition: "all 0.1s ease",
                "&:hover": { transform: "scale3d(1.02, 1.02, 1)", cursor: "pointer" },
              }),
            }}
          >
            {/* Thumbnail */}
            <Box
              component="img"
              src={`https://i.ytimg.com/vi/${data.id}/mqdefault.jpg`}
              sx={{
                ...thumbnailStyles.sx,
                width: "100%",
                boxShadow: 1,
                // transition: "all 0.1s ease",
                // "&:hover": { transform: "scale3d(1.02, 1.02, 1)", cursor: "pointer" },
              }}
              onClick={() => {
                if (!isMobile) redirectToYouTubeVideo();
              }}
              onLoad={handleImageLoad}
              alt="No image"
            />

            {/* Favorite marker */}
            {enableFavoriteInteraction && (
              <Box
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 40,
                  height: 40,
                  ...((thumbnailIsHovered || isMobile) && {
                    background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
                  }),
                  ...(!thumbnailIsHovered &&
                    !isMobile && {
                      background: null,
                    }),
                }}
              >
                {favorite && (
                  <Tooltip title={favoriteTooltipText}>
                    <FavoriteIcon
                      onClick={() => setFavorite()}
                      sx={{ color: theme.palette.red.main, cursor: "pointer" }}
                    />
                  </Tooltip>
                )}
                {(thumbnailIsHovered || isMobile) && !favorite && (
                  <Tooltip title={favoriteTooltipText}>
                    <FavoriteBorderIcon
                      onClick={() => setFavorite()}
                      sx={{ color: theme.palette.white.main, cursor: "pointer" }}
                    />
                  </Tooltip>
                )}
              </Box>
            )}

            {/* Video duration */}
            <Box
              sx={{
                position: "absolute",
                bottom: "13px",
                right: "5px",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: theme.palette.white.main,
                borderRadius: "5px",
                px: "5px",
                fontWeight: 700,
                py: 0,
              }}
            >
              <Typography variant="body4" sx={{ fontWeight: 700 }}>
                {convertVideoLength(data.duration)}
              </Typography>
            </Box>
          </Box>

          {/* Information about the video */}
          <Box sx={{ my: 0.5 }}>
            {/* First video information row */}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", my: 0.5 }}
            >
              {/* Performance Score */}

              {data.med_view_score ? (
                <PerformanceScoreChip score={data.med_view_score} />
              ) : (
                <Box
                  sx={{ display: "flex", alignItems: "center", p: 0, m: 0, height: 24, border: 0, borderRadius: 10 }}
                >
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: 120,
                      height: "100%",
                      borderRadius: 10,
                      p: 0,
                      m: 0,
                      bgcolor: theme.palette.grey.light,
                    }}
                  />
                </Box>
              )}

              {/* Views vs avg views */}
              <Typography
                variant="body4"
                sx={{
                  fontWeight: 300,
                  textAlign: "right",
                }}
              >
                <span style={{ fontWeight: "bold" }}>{formatScore(data.view_count) + " views"}</span>

                {data.channel_med_views ? (
                  data.channel_med_views > 0 ? (
                    <Tooltip
                      arrow
                      title={
                        <Box sx={{ textAlign: "center" }}>
                          <>
                            Average views per video of this YouTube channel
                            <br /> <br />
                            This info is provided by glowAI and not by YouTube
                          </>
                        </Box>
                      }
                    >
                      <span>{" (vs " + formatScore(data.channel_med_views) + " avg)"}</span>
                    </Tooltip>
                  ) : (
                    <span>""</span>
                  )
                ) : (
                  <Box sx={{ display: "inline-flex", alignItems: "center", p: 0, m: 0, ml: 1 }}>
                    <Skeleton
                      variant="text"
                      sx={{
                        width: 40,
                        height: "100%",
                        p: 0,
                        m: 0,
                        bgcolor: theme.palette.grey.light,
                      }}
                    />
                  </Box>
                )}
              </Typography>
            </Box>

            {/* Video title */}
            <Typography
              variant="body3"
              onClick={redirectToYouTubeVideo}
              sx={{
                fontWeight: 500,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                flex: "1 1 auto",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {data.title}
            </Typography>

            {/* Second video information row */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "top", width: "100%" }}>
              {/* Channel name */}
              <Typography
                variant="body4"
                onClick={redirectToYouTubeChannel}
                sx={{
                  fontWeight: 300,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "calc(100% - 100px)",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {data.channel_title}
              </Typography>

              {/* Video age */}
              <Typography
                variant="body4"
                sx={{
                  fontWeight: 300,
                }}
              >
                {calculateVideoAge(data.published_at) + " ago"}
              </Typography>
            </Box>

            {/* Third video information row */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", mb: 1 }}>
              {/* Subscriber count */}
              <Typography
                variant="body4"
                sx={{
                  fontWeight: 300,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "calc(100% - 100px)",
                  whiteSpace: "nowrap",
                }}
              >
                {data.subscriber_count ? (
                  data.subscriber_count > 0 ? (
                    "(" + formatScore(data.subscriber_count) + " subscribers)"
                  ) : (
                    ""
                  )
                ) : (
                  <Box sx={{ display: "inline-flex", alignItems: "center", p: 0, m: 0 }}>
                    <Skeleton
                      variant="text"
                      sx={{
                        width: 40,
                        height: "100%",
                        p: 0,
                        m: 0,
                        bgcolor: theme.palette.grey.light,
                      }}
                    />
                  </Box>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Modal
          open={openAuthPopup}
          onClose={handleClose}
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <>
            <SignInBox
              headerText="Sign in to save this thumbnail to your favorites - it's free!"
              linkToAfterSignIn={null}
              functionToExecuteAfterSignIn={async () => {
                await fetchFavoritesFromFirestore();
                addToFavorites(data);
                setOpenAuthPopup(false);
              }}
            />
          </>
        </Modal>
      </>
    )
  );
}

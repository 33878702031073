import { Stack } from "@mui/material";
import { stackStyles } from "styles/appBarInput";
import { SettingsButton } from "./SettingsButton";
import { VideoSummaryTextField } from "./VideoSummaryTextField";

export function IdeationInput() {
  return (
    <Stack {...stackStyles}>
      <VideoSummaryTextField />
      <SettingsButton />
    </Stack>
  );
}

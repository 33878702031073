import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useIsMobile, useIsTablet } from "styles/breakpoint";

export function LegalLinks() {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  useEffect(() => {
    const checkCookieYesInitialized = setInterval(() => {
      if (window.CookieYes && typeof window.CookieYes.revoke !== "undefined") {
        clearInterval(checkCookieYesInitialized);

        // Add click event listener to the Cookie Settings link
        const cookieSettingsLink = document.querySelector(".cky-banner-element");
        if (cookieSettingsLink) {
          cookieSettingsLink.addEventListener("click", (e) => {
            e.preventDefault();
            window.CookieYes.revoke(); // Trigger the consent banner
          });
        }
      }
    }, 100);

    return () => clearInterval(checkCookieYesInitialized);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection={isTablet || isMobile ? "column" : "row"}
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Box
        display="flex"
        justifyContent={isMobile ? "left" : isTablet ? "center" : "flex-start"}
        width={isTablet || isMobile ? "100%" : "28%"}
        mb={isTablet || isMobile ? 2 : 0}
      >
        <Typography variant="body2">© {new Date().getFullYear()} glowAI. All rights reserved.</Typography>
      </Box>
      <Box display="flex" justifyContent={isMobile ? "left" : "center"} width={isTablet || isMobile ? "100%" : "50%"}>
        <Box
          display="flex"
          justifyContent={isMobile ? "left" : "center"}
          flexWrap="wrap"
          flexDirection={isMobile ? "column" : "row"}
        >
          <Box
            component={Link}
            to="/legal/privacy"
            sx={{ textDecoration: "none", color: "inherit", mr: isMobile ? 0 : 0.5 }}
          >
            <Typography variant="body2" component="div">
              Privacy policy
            </Typography>
          </Box>
          {isMobile ? (
            ""
          ) : (
            <Typography variant="body2" component="div">
              {" "}
              |{" "}
            </Typography>
          )}
          <Box
            component={Link}
            to="/legal/terms"
            sx={{ textDecoration: "none", color: "inherit", mx: isMobile ? 0 : 0.5 }}
          >
            <Typography variant="body2" component="div">
              Terms of service
            </Typography>
          </Box>
          {isMobile ? (
            ""
          ) : (
            <Typography variant="body2" component="div">
              {" "}
              |{" "}
            </Typography>
          )}
          <Box
            component={Link}
            to="/legal/imprint"
            sx={{ textDecoration: "none", color: "inherit", mx: isMobile ? 0 : 0.5 }}
          >
            <Typography variant="body2" component="div">
              Imprint
            </Typography>
          </Box>
          {isMobile ? (
            ""
          ) : (
            <Typography variant="body2" component="div">
              {" "}
              |{" "}
            </Typography>
          )}
          <Box
            component="a"
            href="#"
            className="cky-banner-element"
            sx={{ textDecoration: "none", color: "inherit", ml: isMobile ? 0 : 0.5 }}
          >
            <Typography variant="body2" component="div">
              Cookie Settings
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box flexBasis="25%"></Box>
    </Box>
  );
}

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, IconButton } from "@mui/material";
import theme from "styles/theme";
import { thumbnailStyles } from "styles/thumbnail";

export function AddThumbnailPlaceholder({ handleClick, disabled }) {
  return (
    <Box
      sx={{
        ...thumbnailStyles.sx,
        borderWidth: 1,
        borderStyle: "dashed",
        borderColor: theme.palette.grey.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        aspectRatio: "16 / 9",
        boxSizing: "border-box",
      }}
    >
      <IconButton onClick={handleClick} disabled={disabled}>
        <AddCircleOutlineIcon
          sx={{ color: disabled ? theme.palette.grey.main : theme.palette.primary.main, width: 40, height: 40 }}
        />
      </IconButton>
    </Box>
  );
}

import { Box, CircularProgress, Pagination, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useConfigure, useInfiniteHits, useInstantSearch } from "react-instantsearch";
import { useIsLargeDesktop } from "styles/breakpoint";
import { getSortedFavoritesDataFromFirestore, removeVideoFromFirestoreFavorites } from "../api/firestore";
import { INDEX_NAME } from "../api/typesense/config";
import { triggerVideoIdSearch } from "../api/typesense/regularSearch";
import { sliceObject } from "../utils/sliceObject";
import { VideoPreview } from "./VideoPreview";

export function FavoritesGrid({ userState }) {
  // LOCAL STATE START

  // All Firestore information about the videos which the user saved to favorites {id1: data, id2: date } sorted by the
  // time they were saved from latest to oldest
  const [allFirestoreFavorites, setAllFirestoreFavorites] = useState({});

  // A subset of favorite videos that should be displayed (initially it will inlcude the information from Firestore
  // which is subsequently updated with the information from Typesense)
  const [favoritesToDisplay, setFavoritesToDisplay] = useState({});

  // The current page number
  const [page, setPage] = useState(1);

  // Whether the initial load is completed
  const [initialLoad, setInitialLoad] = useState(true);

  // LOCAL STATE END

  // CONSTANTS START

  const isLargeDesktop = useIsLargeDesktop();
  const max_videos_per_page = isLargeDesktop ? 25 : 24;

  // CONSTANTS END

  // SEARCH STATE START

  const { setIndexUiState, status } = useInstantSearch();
  const { hits } = useInfiniteHits();
  useConfigure({ hitsPerPage: max_videos_per_page, index: INDEX_NAME });

  // SEARCH STATE END

  // USE EFFECT START

  // When loading component for the first time
  useEffect(() => {
    (async () => {
      // Fetch all favorites from Firestore
      const newAllFirestoreFavorites = await getSortedFavoritesDataFromFirestore();
      setAllFirestoreFavorites(newAllFirestoreFavorites);

      // Update favorites to display
      updateFavoritesToDisplay(newAllFirestoreFavorites, page);

      // Inital load completed
      setInitialLoad(false);
    })();
  }, []);

  // Once new Typesense hits are fetched, we can update the local state with the new information
  useEffect(() => {
    const updatedFavoritesToDisplay = { ...favoritesToDisplay };
    hits.forEach((hit) => {
      if (updatedFavoritesToDisplay[hit.id]) {
        updatedFavoritesToDisplay[hit.id] = hit;
      }
    });
    setFavoritesToDisplay(updatedFavoritesToDisplay);
  }, [hits]);

  // USE EFFECT END

  // LOCAL FUNCTIONS START

  const updateFavoritesToDisplay = async (newAllFirestoreFavorites, newPage) => {
    // Get the subset that should be displayed on the page
    const newFavoritesToDisplay = sliceObject(
      newAllFirestoreFavorites,
      (newPage - 1) * max_videos_per_page,
      newPage * max_videos_per_page,
    );
    setFavoritesToDisplay(newFavoritesToDisplay);

    // Fetch video information from Typesense
    triggerVideoIdSearch(setIndexUiState, Object.keys(newFavoritesToDisplay));
  };

  // Remove video ID from favorites and push to firestore
  const handleRemoveFromFavoritesClick = (videoId) => {
    // First update local state to show the favorite update without delay to user
    const { [videoId]: _, ...newAllFirestoreFavorites } = allFirestoreFavorites;
    setAllFirestoreFavorites(newAllFirestoreFavorites);
    updateFavoritesToDisplay(newAllFirestoreFavorites, page);

    // Then update Firestore
    removeVideoFromFirestoreFavorites(videoId);
  };

  // Handle page change
  const handleChangePageClick = (newPage) => {
    setPage(newPage);
    updateFavoritesToDisplay(allFirestoreFavorites, newPage);
  };

  // LOCAL FUNCTIONS END

  return (
    <Grid
      container
      spacing={2}
      columns={60}
      sx={{ alignItems: "flex-start", justifyContent: "left", display: "flex" }}
    >
      {/* Display loading sign, while info is fetched from Firestore */}
      {initialLoad && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", m: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Display text if no favorites were saved by the user */}
      {!initialLoad && Object.keys(favoritesToDisplay).length === 0 && status === "idle" && (
        <Grid mobile={12}>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            It looks like you haven't saved any favorites yet.
          </Typography>
        </Grid>
      )}

      {/* Display video previews of favorites sorted by the time they were saved from latest to oldest */}
      {Object.values(favoritesToDisplay).map((hit, index) => (
        <Grid
          mobile={60}
          tablet={30}
          smallDesktop={20}
          mediumDesktop={15}
          largeDesktop={12}
          veryLargeDesktop={10}
          key={hit.id}
        >
          <VideoPreview
            data={hit}
            userState={userState}
            favorite={true} // All videos on the favorites page are favorites
            enableFavoriteInteraction={true}
            addToFavorites={null} // You cannot add to favorites on the favorites page
            removeFromFavorites={handleRemoveFromFavoritesClick}
          />
        </Grid>
      ))}

      {/* Display the pagination component */}
      {Object.keys(allFirestoreFavorites).length > max_videos_per_page && (
        <Grid mobile={60} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(Object.keys(allFirestoreFavorites).length / max_videos_per_page)}
            page={page}
            onChange={(event, value) => handleChangePageClick(value)}
          />
        </Grid>
      )}
    </Grid>
  );
}

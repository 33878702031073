// Component imports
import { auth } from "config/firebase";
import { checkBetaUser, checkDeveloper } from "features/authentication";
import { checkCustomer } from "features/checkout";

export const createAuthStateListener = (setIsLoading, setUserState) => {
  return auth.onAuthStateChanged(async (user) => {
    setIsLoading(true);
    if (user) {
      // User is signed in
      const developer = await checkDeveloper(user.email);
      const betaUser = await checkBetaUser(user.email);
      const customer = await checkCustomer();
      setUserState({
        authenticated: true,
        developer: developer,
        betaUser: betaUser,
        customer: customer,
      });
      setIsLoading(false);
    } else {
      // User is signed out
      setUserState({
        authenticated: false,
        betaUser: false,
        developer: false,
        customer: false,
      });
      setIsLoading(false);
    }
  });
};

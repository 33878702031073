import { Box } from "@mui/material";
import { FeedbackSpeedDial } from "features/feedback";
import { CustomHelmet } from "features/helmet";
import { ConceptGrid, IdeationInput, IdeationProvider } from "features/ideation";
import { ApplicationAppBar, ApplicationDrawer } from "features/navigation";
import { mainBoxStyles, outerBoxStyles } from "styles/layout";

export default function IdeationPage({ userState }) {
  return (
    <>
      <CustomHelmet index={false} follow={false} />
      <IdeationProvider>
        <Box {...outerBoxStyles}>
          <ApplicationAppBar userState={userState}>
            <IdeationInput />
          </ApplicationAppBar>
          <ApplicationDrawer userState={userState} />
          <FeedbackSpeedDial />
          <Box {...mainBoxStyles}>
            <ConceptGrid />
          </Box>
        </Box>
      </IdeationProvider>
    </>
  );
}

import { BuilderComponent } from "@builder.io/react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Footer } from "features/footer";
import { CustomHelmet } from "features/helmet";
import { LandingAppBar } from "features/navigation";
import { landingContentStyles, landingMainStyles } from "styles/layout";

export default function ImprintPage() {
  return (
    <>
      <CustomHelmet index={false} follow={false} />
      <Box {...landingMainStyles}>
        <LandingAppBar componentBackground="white" />
        <Box {...landingContentStyles}>
          <Typography variant="h1">Imprint</Typography>
          <BuilderComponent model="imprint" />
        </Box>
        <Footer />
      </Box>
    </>
  );
}

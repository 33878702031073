import { Typography } from "@mui/material";
import theme from "styles/theme";

export function Text({ text }) {
  return (
    <Typography
      variant="body1"
      sx={{
        "& a": {
          color: theme.palette.primary.main,
          textDecoration: "none",
        },
        "& > :first-of-type": {
          marginTop: 0,
        },
        "& > :last-of-type": {
          marginBottom: 0,
        },
        mt: 2,
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}

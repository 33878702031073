import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useIsMobile } from "styles/breakpoint";
import { fetchAllArticlesFromBuilder } from "../api/builder";
import { ArticlePreview } from "./ArticlePreview";
import { HighlightArticlePreview } from "./HighlightArticlePreview";

export function BlogGrid() {
  const [articles, setArticles] = useState([]);

  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchArticles = async () => {
      const fetchedArticles = await fetchAllArticlesFromBuilder();
      setArticles(fetchedArticles);
    };

    fetchArticles();
  }, []);

  return (
    <>
      {articles.length > 0 && <HighlightArticlePreview data={articles[0].data} />}
      <Typography variant="h1" sx={{ pt: 6, pb: 4 }}>
        Latest Articles
      </Typography>
      <Grid container spacing={isMobile ? 4 : 6} sx={{ alignItems: "flex-start", display: "flex" }}>
        {articles.length > 1 &&
          articles.slice(1).map((article, index) => (
            <Grid mobile={12} tablet={6} mediumDesktop={4} key={article.id}>
              <ArticlePreview data={article.data} />
            </Grid>
          ))}
      </Grid>
    </>
  );
}

import { IconButton, InputAdornment, TextField } from "@mui/material";
import { textFieldStyles } from "styles/appBarInput";

export function AppBarInputTextField({ userInput, setUserInput, placeholderText, icon, handleClick }) {
  return (
    <TextField
      {...textFieldStyles}
      InputProps={{
        ...textFieldStyles.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!userInput}
              sx={{
                ":hover": { color: userInput ? "primary.main" : "default" },
              }}
              onClick={() => handleClick()}
            >
              {icon}
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder={placeholderText}
      onKeyDown={(e) => {
        if (e.key === "Enter" && userInput) {
          handleClick();
        }
      }}
      onChange={(e) => setUserInput(e.target.value)}
    />
  );
}

import { APP_BAR_HEIGHT, DRAWER_HEIGHT } from "features/navigation";

export const MAX_SECTION_WIDTH = "1200px";

export const outerBoxStyles = {
  display: "flex",
};

export const mainBoxStyles = {
  component: "main",
  sx: { mt: APP_BAR_HEIGHT, mb: { mobile: `${DRAWER_HEIGHT}px`, tablet: 0 }, p: 2, width: "100%" },
};

export const landingContentStyles = {
  sx: {
    width: "100%",
    maxWidth: MAX_SECTION_WIDTH,
    px: 2,
  },
};

export const landingMainStyles = {
  sx: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

import { Box, CircularProgress } from "@mui/material";
import theme from "styles/theme";
import { thumbnailStyles } from "styles/thumbnail";

export function LoadingThumbnailPlaceholder() {
  return (
    <Box
      sx={{
        ...thumbnailStyles.sx,
        backgroundColor: theme.palette.grey.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        aspectRatio: "16 / 9",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

import { Box } from "@mui/system";
import authenticationBackground from "assets/backgrounds/authentication.svg";
import { SignInBox } from "features/authentication";
import { CustomHelmet } from "features/helmet";

export default function AuthenticationPage() {
  return (
    <>
      <CustomHelmet index={false} follow={false} />
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundImage: `url(${authenticationBackground})`,
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SignInBox />
      </Box>
    </>
  );
}

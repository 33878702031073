import { functions } from "config/firebase";
import { httpsCallable } from "firebase/functions";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

// TYPESENSE PARAMETERS

// Name of the index
export const INDEX_NAME = process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION;

// Interval for refreshing search client in milliseconds
export const REFRESH_MS = 1000 * 4.5 * 60; // 4.5 minutes

// Number of search hits that should be displayed per page
export const HITS_PER_PAGE = 60;

// Search parameters (https://typesense.org/docs/26.0/api/search.html#query-parameters)
const ADDITIONAL_SEARCH_PARAMETERS = {
  // Columns considered for text match (order is important)
  query_by: "title,embedding,search_terms_relevant_str,channel_title", //"title,channel_title" // search_terms,embedding

  // Indicates that the last word in the query should be treated as a prefix, and not as a whole word. This is
  // necessary for building autocomplete and instant search interfaces. Set this to false to disable prefix searching
  // for all queried fields.
  prefix: "true,false,false,false",

  // Columns to return in the search results
  include_fields:
    "id,med_view_score,view_count,subscriber_count,channel_title,title,duration,channel_med_views,published_at,custom_url,search_terms_relevant_str,rand_id",

  // Maximum number of typographical errors (0, 1 or 2) that would be tolerated
  num_typos: 2,

  // Minimum word length for 1-typo correction to be applied
  min_len_1typo: 5,

  // Minimum word length for 2-typo correction to be applied
  min_len_2typo: 8,

  // If drop_tokens_threshold is set to a number N and a search query contains multiple words (eg: wordA wordB), if at
  // least N results with both wordA and wordB in the same document are not found, then Typesense will drop wordB and
  // search for documents with just wordA. Typesense will keep dropping keywords like this left to right and/or right
  // to left, until at least N documents are found. Words that have the least individual results are dropped first. Set
  //drop_tokens_threshold to 0 to disable dropping of words (tokens).
  drop_tokens_threshold: 0,

  // Treat space as typo: search for q=basket ball if q=basketball is not found or vice-versa. Splitting/joining of
  // tokens will only be attempted if the original query produces no results. To always trigger this behavior, set
  // value to always. To disable, set value to off.
  split_join_tokens: "fallback",
};

const EMBEDDING_PARAMETERS = {
  // When using hybrid search, this parameter specifies the weight between text match and vector search. A value of 0
  // means that only text match is used, a value of 1 means that only vector search is used. Only relevant when sorting
  // by hybrid search.
  alpha: 0.5,

  // The maximum number of nearest neighbors that is returned from the vector search
  k: 10000,

  // The cutt off vector distance threshold. Everything above this threshold will be ignored
  distance_threshold: 0.135,
};

// SEARCH CONFIG

// Setup Typsense with additional search parameters
export function setupSearch(retrievingKeys, setRetrievingKeys, setTypesenseInstance, setInitialLoad) {
  if (!retrievingKeys) {
    setRetrievingKeys(true);
    const getSearchConfig = httpsCallable(functions, "get-search-config", {
      region: process.env.REACT_APP_CLOUD_FUNCTION_REGION,
    });
    // get new key with cloud function
    getSearchConfig()
      .then((result) => {
        setTypesenseInstance(
          new TypesenseInstantSearchAdapter({
            server: {
              nodes: result.data.nodes,
              apiKey: result.data.apiKey,
            },
            additionalSearchParameters: ADDITIONAL_SEARCH_PARAMETERS,
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setRetrievingKeys(false);
        setInitialLoad(false);
      });
  }
}

// This function is called everytime the ui state of typesense changes
export async function searchFunction(helper) {
  const page = helper.getPage(); // Retrieve the current page
  helper
    .setQueryParameter(
      "typesenseVectorQuery", // <=== Special parameter that only works in typesense-instantsearch-adapter@2.7.0-3 and above
      `embedding:([], k:${EMBEDDING_PARAMETERS.k}, alpha:${EMBEDDING_PARAMETERS.alpha}, distance_threshold:${EMBEDDING_PARAMETERS.distance_threshold})`,
    )
    .setPage(page)
    .search();
}

import { Box, Typography } from "@mui/material";
import theme from "styles/theme";

export function Faq({ question, answer }) {
  return (
    <Box sx={{ borderRadius: "25px", boxShadow: 1, p: 2, mb: 2 }}>
      <Typography variant="h5" component="h3" sx={{ mb: 2 }}>
        {question}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          "& a": {
            color: theme.palette.primary.main,
            textDecoration: "none",
          },
          "& > :first-of-type": {
            marginTop: 0,
          },
          "& > :last-of-type": {
            marginBottom: 0,
          },
          mt: 2,
        }}
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </Box>
  );
}

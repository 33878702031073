import { Box } from "@mui/system";
import { BlogGrid } from "features/blog";
import { Footer } from "features/footer";
import { CustomHelmet } from "features/helmet";
import { LandingAppBar } from "features/navigation";
import { landingContentStyles, landingMainStyles } from "styles/layout";

export default function BlogPage() {
  return (
    <>
      <CustomHelmet
        title={"glowAI Blog"}
        description={
          "Get the latest insights, tips and best practices on YouTube thumbnails and strategies to boost your YouTube channel."
        }
      />
      <Box {...landingMainStyles}>
        <LandingAppBar componentBackground="white" />
        <Box {...landingContentStyles}>
          <BlogGrid />
        </Box>
        <Footer />
      </Box>
    </>
  );
}

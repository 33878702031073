import TuneIcon from "@mui/icons-material/Tune";
import { Button, IconButton, Typography } from "@mui/material";
import { useIsDesktop } from "styles/breakpoint";
import theme from "styles/theme";

export function AppBarTuneButton({ buttonText, handleOpen }) {
  // Breakpoint
  const isDesktop = useIsDesktop();
  return isDesktop ? (
    <Button
      variant="outlined"
      endIcon={<TuneIcon sx={{ color: theme.palette.grey.dark }} />}
      onClick={handleOpen}
      sx={{
        height: { mobile: 10, tablet: 40 },
        px: 3,
        boxShadow: 0,
        textTransform: "none",
        bgcolor: theme.palette.white.main,
        borderRadius: 10,
        color: theme.palette.grey.dark,
        borderColor: theme.palette.grey.main,
        "&:hover": {
          borderColor: theme.palette.black.main,
          bgcolor: theme.palette.white.main,
          borderWidth: 1,
        },
      }}
    >
      <Typography variant="button">{buttonText}</Typography>
    </Button>
  ) : (
    <IconButton onClick={handleOpen}>
      <TuneIcon color={theme.palette.grey.dark} />
    </IconButton>
  );
}

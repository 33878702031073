import { createContext, useContext, useState } from "react";

const FeedbackContext = createContext();

export function FeedbackProvider({ children }) {
  // Local state
  // Inputs need to be stored in context to not erase the input if the user closes the popover
  const [bugReportInputText, setBugReportInputText] = useState("");
  const [bugReportInputImages, setBugReportInputImages] = useState([]);

  const [featureRequestInputText, setFeatureRequestInputText] = useState("");
  const [featureRequestInputImages, setFeatureRequestInputImages] = useState([]);

  const [generalFeedbackInputText, setGeneralFeedbackInputText] = useState("");

  return (
    <FeedbackContext.Provider
      value={{
        bugReportInputText,
        setBugReportInputText,
        bugReportInputImages,
        setBugReportInputImages,
        featureRequestInputText,
        setFeatureRequestInputText,
        featureRequestInputImages,
        setFeatureRequestInputImages,
        generalFeedbackInputText,
        setGeneralFeedbackInputText,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
}

export function useFeedbackContext() {
  return useContext(FeedbackContext);
}

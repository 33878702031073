export const thumbnailCollection = [
  { videoId: "BYVZh5kqaFg", score: 90.7066 },
  { videoId: "fkIygLMFcI8", score: 87.2722 },
  { videoId: "Nb1PrONDHhk", score: 76.0708 },
  { videoId: "IV3dnLzthDA", score: 91.0779 },
  { videoId: "JdFRjsEZrmU", score: 82.869 },
  { videoId: "QpwJEYGCngI", score: 72.1744 },
  { videoId: "83Qghmdz3RQ", score: 72.1052 },
  { videoId: "dtp6b76pMak", score: 95.4151 },
  { videoId: "Jb8T9X5K1AA", score: 82.2049 },
  { videoId: "Y5b0DKfhPc4", score: 78.9002 },
  { videoId: "AF8d72mA41M", score: 90.5844 },
  { videoId: "8cvhwquPqJ0", score: 91.5187 },
  { videoId: "H3_mVryqXpU", score: 91.7904 },
  { videoId: "dHy-qfkO54E", score: 73 },
  { videoId: "ekgUjyWe1Yc", score: 97 },
];

export function getRandomItems(arr, numItems) {
  // Shuffle array using Durstenfeld shuffle algorithm
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]]; // Swap elements
  }
  // Slice the first numItems elements from the shuffled array
  return arr.slice(0, numItems);
}

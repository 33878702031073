import { Stack } from "@mui/material";
import { stackStyles } from "styles/appBarInput";
import { FiltersButton } from "./FiltersButton";
import { SearchTextField } from "./SearchTextField";

export function SearchInput() {
  return (
    <Stack {...stackStyles}>
      <SearchTextField />
      <FiltersButton />
    </Stack>
  );
}

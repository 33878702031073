import { useMediaQuery } from "@mui/material";

export function useIsMobile() {
  return useMediaQuery((theme) => theme.breakpoints.only("mobile"));
}

export function useIsTablet() {
  return useMediaQuery((theme) => theme.breakpoints.only("tablet"));
}

export function useIsDesktop() {
  return useMediaQuery((theme) => theme.breakpoints.up("smallDesktop"));
}

export function useIsSmallDesktop() {
  return useMediaQuery((theme) => theme.breakpoints.only("smallDesktop"));
}

export function useIsMediumDesktop() {
  return useMediaQuery((theme) => theme.breakpoints.only("mediumDesktop"));
}

export function useIsLargeDesktop() {
  return useMediaQuery((theme) => theme.breakpoints.only("largeDesktop"));
}

export function useIsVeryLargeDesktop() {
  return useMediaQuery((theme) => theme.breakpoints.only("veryLargeDesktop"));
}

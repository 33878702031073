import SendIcon from "@mui/icons-material/Send";
import { AppBarInputTextField } from "components/appBar/AppBarInputTextField";
import { useIsMobile } from "styles/breakpoint";
import { videoSummaryToConcepts } from "../api/cloudFunctions";
import { useIdeationContext } from "./IdeationContext";

export function VideoSummaryTextField() {
  // Context state
  const { videoSummary, setVideoSummary, textModelSetting, nrConceptsSetting, setConcepts } = useIdeationContext();

  // Breakpoint
  const isMobile = useIsMobile();
  const placeholderText = isMobile ? "Video Summary" : "What is your video about?";

  // Generate new thumbnail concepts based on the video summary
  const handleSendClick = async () => {
    let listOfEmptyDicts = Array.from({ length: nrConceptsSetting }, () => ({}));
    setConcepts(listOfEmptyDicts);
    const response = await videoSummaryToConcepts({
      video_summary: videoSummary,
      model: textModelSetting,
      nr_concepts_to_generate: nrConceptsSetting,
      concepts_to_exclude: "",
    });
    setConcepts(response.data.concepts);
  };

  return (
    <AppBarInputTextField
      userInput={videoSummary}
      setUserInput={setVideoSummary}
      placeholderText={placeholderText}
      icon={<SendIcon />}
      handleClick={handleSendClick}
    />
  );
}

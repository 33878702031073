import { Divider, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useIsMobile } from "styles/breakpoint";
import theme from "styles/theme";
import { formatDate } from "../utils/formatting";
import { ArticleThumbnail } from "./ArticleThumbnail";

export function ArticleHeader({ data }) {
  const [formattedDate, setFormattedDate] = useState(null);

  // Breakpoint
  const isMobile = useIsMobile();

  // Format date if data changes
  useEffect(() => {
    setFormattedDate(formatDate(data.date));
  }, [data]);

  return (
    data && (
      <Grid container spacing={3} sx={{ alignItems: "center", justifyContent: "center" }}>
        <Grid mobile={12} sx={{ display: "flex", justifyContent: "center" }}>
          {/* Category */}
          <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main }}>
            {data.category}
          </Typography>
        </Grid>
        <Grid mobile={12} sx={{ display: "flex", justifyContent: "center" }}>
          {/* Title */}
          <Typography variant="h1" sx={{ textAlign: "center", width: { mobile: "100%", tablet: "80%" } }}>
            {data.title}
          </Typography>
        </Grid>

        <Grid mobile={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? 0 : 3}>
            {/* Date */}
            <Typography variant="subtitle1" sx={{ textAlign: "center", color: theme.palette.grey.dark }}>
              {formattedDate}
            </Typography>

            {/* Vertical divider */}
            {!isMobile && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderWidth: 0.5, borderColor: theme.palette.grey.main }}
              />
            )}

            {/* Author */}
            <Typography variant="subtitle1" sx={{ textAlign: "center", color: theme.palette.grey.dark }}>
              WRITTEN BY {data.author}
            </Typography>
          </Stack>
        </Grid>
        <Grid mobile={12} sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
          {/* Thumbnail */}
          <ArticleThumbnail image={data.image} title={data.title} />
        </Grid>
      </Grid>
    )
  );
}

import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

export async function checkBetaUser(email) {
  const docSnap = await getDoc(doc(db, "beta-users", email));
  if (docSnap.exists()) {
    return true;
  } else {
    return false;
  }
}

export async function checkDeveloper(email) {
  const docSnap = await getDoc(doc(db, "developers", email));
  if (docSnap.exists()) {
    return true;
  } else {
    return false;
  }
}

import SendIcon from "@mui/icons-material/Send";
import { AppBarInputTextField } from "components/appBar/AppBarInputTextField";
import { useState } from "react";
import { useIsMobile } from "styles/breakpoint";
import { generateThumbnail } from "../api/cloudFunctions";
import { fetchGeneratedThumbnailsFromFirestore } from "../api/fetchGeneratedThumbnails";
import { usePromptContext } from "./PromptContext";

export function PromptTextField() {
  // Context state
  const { setPage, incrementLoadingCount, decrementLoadingCount, modelSetting, setThumbnailGrid } = usePromptContext();

  // Local state
  const [prompt, setPrompt] = useState("");

  // Responsive design
  const isMobile = useIsMobile();
  const placeholderText = isMobile ? "Prompt" : "Enter a prompt to generate an image ...";

  // On send, the thumbnail is generated and the new thumbnail is fetched from firebase
  const handleSendClick = async () => {
    setPage(1);
    incrementLoadingCount();
    try {
      await generateThumbnail({ prompt: prompt, model: modelSetting });
      const thumbnails = await fetchGeneratedThumbnailsFromFirestore();
      setThumbnailGrid((prev) => ({ loadingCount: Math.max(prev.loadingCount - 1, 0), thumbnails: thumbnails }));
    } catch (error) {
      console.error("Error generating image:", error);
      decrementLoadingCount();
    }
  };

  return (
    <AppBarInputTextField
      userInput={prompt}
      setUserInput={setPrompt}
      placeholderText={placeholderText}
      icon={<SendIcon />}
      handleClick={handleSendClick}
    />
  );
}

import { Box, Divider, Stack, Typography } from "@mui/material";
import footerBackground from "assets/backgrounds/footer.svg";
import Facebook from "assets/logos/Facebook.svg";
import glowAILog from "assets/logos/glowAI_icon_black_and_logo_black.svg";
import Instagram from "assets/logos/Instagram.svg";
import TwitterX from "assets/logos/TwitterX.svg";
import { Link } from "react-router-dom";
import { useIsMobile } from "styles/breakpoint";
import { landingContentStyles } from "styles/layout";
import { LegalLinks } from "./LegalLinks";

export function Footer() {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        width: "100%",
        height: { mobile: "500px", tablet: "350px", smallDesktop: "350px" },
        backgroundImage: `url(${footerBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "top left",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      <Stack
        direction="column"
        spacing={isMobile ? 4 : 6}
        sx={{
          ...landingContentStyles.sx,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: { mobile: "flex-start", tablet: "center" },
          }}
        >
          <Link to="/" style={{ lineHeight: 0, display: "inline-block" }}>
            <Box component={"img"} src={glowAILog} alt="glowAI Logo Black" sx={{ width: "150px" }} />
          </Link>
          <Typography
            component="a"
            href="mailto:contact@glowlabs.ai"
            variant="body1"
            sx={{ textDecoration: "underline", color: "inherit" }}
          >
            @ Get in touch
          </Typography>
          <Stack direction="row" spacing={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Link
              to="https://www.instagram.com/glowlabs.ai"
              target="_blank"
              rel="noopener noreferrer"
              style={{ lineHeight: 0, display: "inline-block" }}
            >
              <Box component={"img"} src={Instagram} alt="Instagram Logo" sx={{ width: "50px" }} />
            </Link>
            <Link
              to="https://twitter.com/glowlabsAI"
              target="_blank"
              rel="noopener noreferrer"
              style={{ lineHeight: 0, display: "inline-block" }}
            >
              <Box component={"img"} src={TwitterX} alt="X Logo" sx={{ width: "50px" }} />
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=61558722576707"
              target="_blank"
              rel="noopener noreferrer"
              style={{ lineHeight: 0, display: "inline-block" }}
            >
              <Box component={"img"} src={Facebook} alt="Facebook Logo" sx={{ width: "50px" }} />
            </Link>
            {/* TODO: Add LinkedIn Link once we have a LinkedIn page */}
            {/* <Box component={"img"} src={LinkedIn} alt="LinkedIn Logo" sx={{ width: "50px" }} /> */}
          </Stack>
        </Stack>

        <Stack direction="column" sx={{ width: "100%" }}>
          <Divider />
          <Box sx={{ py: { mobile: 2, tablet: 2 } }}>
            <LegalLinks />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

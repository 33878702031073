import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { useIsMobile, useIsTablet } from "styles/breakpoint";
import theme from "styles/theme";
import { ArticleContent } from "./ArticleContent";
import { ArticleFooter } from "./ArticleFooter";
import { ArticleHeader } from "./ArticleHeader";
import { ShareOnSocialMediaButtons } from "./ShareOnSocialMediaButtons";

export function Article({ article }) {
  // Router
  const navigate = useNavigate();

  // Breakpoint
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return (
    <Grid container spacing={0} sx={{ justifyContent: "center", display: "flex" }}>
      {/* Back button */}
      {(isMobile || isTablet) && (
        <Grid mobile={12}>
          <Button
            startIcon={<ChevronLeftIcon />}
            onClick={() => navigate("/blog")}
            sx={{
              color: theme.palette.grey.dark,
              textTransform: "none",
              width: "auto",
              "&:hover": {
                backgroundColor: "transparent",
              },
              ml: -1,
              mb: 4,
            }}
          >
            <Typography variant="button">Back to Blog</Typography>
          </Button>
        </Grid>
      )}

      {/* Article header */}
      <Grid mobile={12} sx={{ justifyContent: "center", display: "flex", maxWidth: "800px" }}>
        {article && <ArticleHeader data={article?.data} />}
      </Grid>

      {/* Article content */}
      <Grid mobile={12} sx={{ justifyContent: "flex-start", display: "flex", maxWidth: "800px", mt: 4 }}>
        {article && <ArticleContent article={article} />}
      </Grid>

      {/* Social media share links */}
      <Grid mobile={12} sx={{ justifyContent: "center", display: "flex", maxWidth: "800px", mt: 6, mb: 6 }}>
        <Stack direction="column" spacing={2} sx={{ justifyContent: "center", display: "flex" }}>
          <Typography variant="subtitle2" sx={{ textAlign: "center", color: theme.palette.grey.dark }}>
            SHARE
          </Typography>
          {article && <ShareOnSocialMediaButtons title={article?.data?.title} />}
        </Stack>
      </Grid>

      {/* Article footer */}
      <Grid mobile={12} sx={{ justifyContent: "center", display: "flex", maxWidth: "800px" }}>
        {article && <ArticleFooter handle={article?.data?.handle} />}
      </Grid>
    </Grid>
  );
}

import theme from "styles/theme";

export function getPerformanceScoreChipColor(performanceScore) {
  if (performanceScore >= 90) {
    return theme.palette.performanceScore._90_100;
  } else if (performanceScore >= 80) {
    return theme.palette.performanceScore._80_90;
  } else if (performanceScore >= 70) {
    return theme.palette.performanceScore._70_80;
  } else if (performanceScore >= 60) {
    return theme.palette.performanceScore._60_70;
  } else if (performanceScore >= 50) {
    return theme.palette.performanceScore._50_60;
  } else if (performanceScore >= 40) {
    return theme.palette.performanceScore._40_50;
  } else if (performanceScore >= 30) {
    return theme.palette.performanceScore._30_40;
  } else if (performanceScore >= 20) {
    return theme.palette.performanceScore._20_30;
  } else if (performanceScore >= 10) {
    return theme.palette.performanceScore._10_20;
  } else {
    return theme.palette.performanceScore._0_10;
  }
}

export function getPerformanceScoreFontColor(performanceScore) {
  if (performanceScore >= 70 || performanceScore < 30) {
    return theme.palette.white.main;
  } else {
    return theme.palette.black.main;
  }
}

export function getPerformanceScoreWording(performanceScore) {
  if (performanceScore >= 90) {
    return "Unicorn";
  } else if (performanceScore >= 80) {
    return "On Fire";
  } else if (performanceScore >= 70) {
    return "Great";
  } else if (performanceScore >= 60) {
    return "Good";
  } else if (performanceScore >= 50) {
    return "Solid";
  } else if (performanceScore >= 40) {
    return "Average";
  } else if (performanceScore >= 30) {
    return "Mediocre";
  } else if (performanceScore >= 20) {
    return "Struggling";
  } else if (performanceScore >= 10) {
    return "Flop";
  } else {
    return "Fiasco";
  }
}

import Grid from "@mui/material/Unstable_Grid2";
import { SingleSelectColumn } from "components/appBar/SingleSelectColumn";
import { TunePopup } from "components/appBar/TunePopup";
import { usePromptContext } from "./PromptContext";

export function SettingsPopup({ open, handleClose }) {
  // Context state
  const { modelSetting, setModelSetting } = usePromptContext();

  return (
    <TunePopup title="Settings" open={open} handleClose={handleClose}>
      {/* Model selection */}
      <Grid mobile={12}>
        <SingleSelectColumn
          title="MODEL"
          availableOptions={[
            "Dummy",
            "Stable Diff. XL 1.0",
            "Stable Diff. 1.6",
            "Stable Img. Core",
            "Stable Diff. 3L",
            "Stable Diff. 3L Turbo",
            "Stable Img. Ultra",
            "OpenAI DALL-E 3",
          ]}
          selectedOption={modelSetting}
          setSelectedOption={setModelSetting}
        />
      </Grid>
    </TunePopup>
  );
}

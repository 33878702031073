import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, InputAdornment, Modal, Paper, TextField, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import theme from "styles/theme";
import { thumbnailStyles } from "styles/thumbnail";
import { downloadThumbnailToLocalDrive } from "../api/downloadThumbnailToLocalDrive";

export function ThumbnailPopup({ open, handleClose, selectedThumbnail }) {
  // Local state
  const [copied, setCopied] = useState(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: { mobile: "50%", tablet: "50%", smallDesktop: "50%" },
          left: { mobile: "5%", tablet: "10%", smallDesktop: "20%" },
          width: { mobile: "90%", tablet: "80%", smallDesktop: "60%" },
          transform: {
            mobile: "translate(0, -50%)",
            tablet: "translate(0, -50%)",
            smallDesktop: "translate(0, -50%)",
          },
          outline: "none",
        }}
      >
        <Paper
          sx={{
            borderRadius: 4,
          }}
        >
          <Grid container spacing={2} sx={{ p: 2 }}>
            {/* TODO: Add close icon in top right corner */}

            {/* Display the selected thumbnail */}
            <Grid mobile={12}>
              <Box {...thumbnailStyles} component={"img"} src={selectedThumbnail?.thumbnail_url} />
            </Grid>

            {/* Display a button to download the thumbnail to local drive */}
            <Grid mobile={12} sx={{ paddingRight: 2, display: "flex", justifyContent: "right" }}>
              <Button
                variant="contained"
                sx={{ borderRadius: 10 }}
                startIcon={<DownloadIcon />}
                onClick={() => downloadThumbnailToLocalDrive(selectedThumbnail?.thumbnail_url)}
              >
                Download
              </Button>
            </Grid>

            {/* Display the model that was used to create the thumbnail */}
            <Grid mobile={12} tablet={2}>
              <Typography variant="body2" sx={{ paddingRight: 2 }}>
                <b>Model:</b>
              </Typography>
            </Grid>

            <Grid mobile={12} tablet={10}>
              <Typography variant="body2">{selectedThumbnail?.model}</Typography>
            </Grid>

            {/* Display the prompt that was used to create the thumbnail */}
            <Grid mobile={12} tablet={2}>
              <Typography variant="body2">
                <b>Prompt:</b>
              </Typography>
            </Grid>

            <Grid mobile={12} tablet={10}>
              <TextField
                sx={{
                  width: "100%",
                  // Change the color of the text field when disabled
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: theme.palette.black.main,
                    color: theme.palette.black.main,
                  },
                }}
                defaultValue={selectedThumbnail?.prompt}
                disabled={true}
                // Option to copy to clipboard
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={copied ? "Copied" : "Copy"} arrow placement="top">
                        <ContentCopyIcon
                          sx={{
                            color: "action.active",
                            mr: 1,
                            my: 0.5,
                            ":hover": { color: theme.palette.black.main },
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(selectedThumbnail?.prompt);
                            setCopied(true);
                            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
                          }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}

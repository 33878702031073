import Grid from "@mui/material/Unstable_Grid2";
import { SingleSelectColumn } from "components/appBar/SingleSelectColumn";
import { TunePopup } from "components/appBar/TunePopup";
import { useIdeationContext } from "./IdeationContext";

export function SettingsPopup({ open, handleClose }) {
  // Context state
  const {
    textModelSetting,
    setTextModelSetting,
    imageModelSetting,
    setImageModelSetting,
    nrConceptsSetting,
    setNrConceptsSetting,
  } = useIdeationContext();

  // Since radio buttons return the selected option as as string, we need to convert the selected option back to a
  // number
  function convertToNumberAndSetNrConceptsSetting(value) {
    setNrConceptsSetting(Number(value));
  }
  return (
    <TunePopup title="Settings" open={open} handleClose={handleClose}>
      {/* Text Model selection */}
      <Grid mobile={6} tablet={4}>
        <SingleSelectColumn
          title="Text MODEL"
          availableOptions={["dummy", "gpt-3.5-turbo", "gpt-4-turbo", "gpt-4o"]}
          selectedOption={textModelSetting}
          setSelectedOption={setTextModelSetting}
        />
      </Grid>
      {/* Image Model selection */}
      <Grid mobile={6} tablet={4}>
        <SingleSelectColumn
          title="IMAGE MODEL"
          availableOptions={[
            "Dummy",
            "Stable Diff. XL 1.0",
            "Stable Diff. 1.6",
            "Stable Img. Core",
            "Stable Diff. 3L",
            "Stable Diff. 3L Turbo",
            "Stable Img. Ultra",
            "OpenAI DALL-E 3",
          ]}
          selectedOption={imageModelSetting}
          setSelectedOption={setImageModelSetting}
        />
      </Grid>
      {/* Number of concepts that should initially be created selection */}
      <Grid mobile={6} tablet={4}>
        <SingleSelectColumn
          title="# CONCEPTS"
          availableOptions={[1, 2, 3, 4, 5]}
          selectedOption={nrConceptsSetting}
          setSelectedOption={convertToNumberAndSetNrConceptsSetting}
        />
      </Grid>
    </TunePopup>
  );
}

import { sendCustomerFeedbackToGoogleChat } from "../api/cloudFunctions";
import { uploadImagesToStorageBucket } from "../api/storeImages";
import { useFeedbackContext } from "./FeedbackContext";
import { FeedbackPopover } from "./FeedbackPopover";

export function FeatureRequestPopover({ open, setOpen, anchorRef }) {
  // Context state
  const {
    featureRequestInputText,
    setFeatureRequestInputText,
    featureRequestInputImages,
    setFeatureRequestInputImages,
  } = useFeedbackContext();

  // Send feature request to google chat on submit
  const handleSubmit = async () => {
    const imageUrls = await uploadImagesToStorageBucket(featureRequestInputImages);
    sendCustomerFeedbackToGoogleChat({
      feedback_type: "feature_request",
      customer_feedback: featureRequestInputText,
      image_urls: imageUrls,
    });
  };
  return (
    <FeedbackPopover
      headerText={"Do you have an idea for a new feature? We're eager to hear your suggestions!"}
      thankYouText={"Thank you for your feature request!"}
      open={open}
      setOpen={setOpen}
      userInput={featureRequestInputText}
      setUserInput={setFeatureRequestInputText}
      handleSubmit={handleSubmit}
      anchorRef={anchorRef}
      imageButtonText={"Add Images"}
      images={featureRequestInputImages}
      setImages={setFeatureRequestInputImages}
    />
  );
}

// This filter string is added to EVERY typesense search
export const MUST_HAVE_FILTER_STRING =
  "published_at:>" + Math.round((Date.now() - 5 * 365 * 24 * 60 * 60 * 1000) / 1000);

// This filter string is added to random searches
export const RANDOM_SEARCH_FILTER_STRING = "med_view_score:>80";

// This sort string is added to random searches
export const RANDOM_SEARCH_SORT_BY_STRING = process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION + "/sort/rand_id:asc";

// Default user filter values
export const DEFAULT_USER_FILTER_VALUES = {
  published_at: "All",
  duration: "All",
  sort_by: "Relevance",
};

// Mapping for user filters to be encoded in URL (needs to be shorter so the URL doesn't get too long)
export const USER_FILTER_URL_ENCODING_MAPPING = {
  published_at: "pu",
  duration: "du",
  sort_by: "so",
};

// Filter options that can be adjusted by the user
export const USER_FILTER_VALUES = {
  sort_by: {
    showName: "Sort by",
    values: {
      Relevance: "/sort/med_view_score:desc", // Alternative: "/sort/score_bucket_10:asc,_text_match:desc,rand_id:desc",
      "View count": "/sort/view_count:desc",
      "Upload date": "/sort/published_at:desc,med_view_score:desc",
    },
  },
  published_at: {
    showName: "Published",
    values: {
      All: null,
      "This month": ">" + Math.round((Date.now() - 30 * 24 * 60 * 60 * 1000) / 1000),
      "Last 6 months": ">" + Math.round((Date.now() - 180 * 24 * 60 * 60 * 1000) / 1000),
      "This year": ">" + Math.round((Date.now() - 365 * 24 * 60 * 60 * 1000) / 1000),
    },
  },
  duration: {
    showName: "Duration",
    values: {
      All: null,
      "Under 4 minutes": "<" + 4 * 60,
      "4–20 minutes": "[" + 4 * 60 + ".." + 20 * 60 + "]",
      "Over 20 minutes": ">" + 20 * 60,
    },
  },
};

// Convert the selected user filters to a filter sting that can be used in Typesense
export const createFilterString = (selectedFilters) => {
  const filterParts = [];

  // Create filter string with selected filters
  for (const [category, value] of Object.entries(selectedFilters)) {
    if (category !== "sort_by" && value !== "All") {
      filterParts.push(`${category}:${USER_FILTER_VALUES[category].values[value]}`);
    }
  }

  return filterParts.join(" && ");
};

// Sort and filter the combined hits
export function filterAndSortCombinedHits(combinedHits, selectedFilters) {
  const sortedAndFilteredCombinedHits = sortCombinedHits(
    filterCombinedHits(combinedHits, selectedFilters),
    selectedFilters,
  );
  return sortedAndFilteredCombinedHits;
}

// Filter logic of the combined live search and typesense hits based on the selected user filters
export function filterCombinedHits(hits, selectedFilters) {
  return hits.filter((video) => {
    return Object.keys(selectedFilters).every((filterName) => {
      if (filterName === "sort_by") {
        return true;
      }
      return compareWithFilter(filterName, selectedFilters[filterName], video[filterName]);
    });
  });
}

// Convert filter typesense notation to a comparison of list elements
function compareWithFilter(filterName, selectedFilterValue, comparisonValue) {
  const filterValue = USER_FILTER_VALUES[filterName].values[selectedFilterValue];

  if (filterValue === null) {
    return true;
  }

  const operator = filterValue.charAt(0);

  switch (operator) {
    case ">":
      return comparisonValue > Number(filterValue.slice(1));
    case "<":
      return comparisonValue < Number(filterValue.slice(1));
    case "[":
    case "]":
      const comparisonCriterion = filterValue.slice(1, -1);
      const [lowerBound, upperBound] = comparisonCriterion.split("..").map(Number);
      return comparisonValue >= lowerBound && comparisonValue <= upperBound;
    default:
      return false;
  }
}

// Sorting logic of the combined live search and typesense hits based on the selected sort method
export function sortCombinedHits(combinedHits, selectedFilters) {
  switch (selectedFilters.sort_by) {
    case "Relevance":
      combinedHits.sort((a, b) => b.med_view_score - a.med_view_score);
      break;
    case "Upload date":
      combinedHits.sort((a, b) => b.published_at - a.published_at);
      break;
    case "View count":
      combinedHits.sort((a, b) => b.view_count - a.view_count);
      break;
    default:
      break;
  }

  return combinedHits;
}

import { Box } from "@mui/material";
import { FeedbackSpeedDial } from "features/feedback";
import { CustomHelmet } from "features/helmet";
import { ApplicationAppBar, ApplicationDrawer } from "features/navigation";
import { FavoritesGrid, INDEX_NAME, REFRESH_MS, setupSearch } from "features/outliers";
import { useEffect, useState } from "react";
import { InstantSearch } from "react-instantsearch";
import { mainBoxStyles, outerBoxStyles } from "styles/layout";

export default function FavoritesPage({ userState }) {
  // Local state
  const [typesenseInstance, setTypesenseInstance] = useState({});
  const [retrievingKeys, setRetrievingKeys] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    // When loading the page for the first time, set up the search client
    if (initialLoad) {
      setupSearch(retrievingKeys, setRetrievingKeys, setTypesenseInstance, setInitialLoad);
    }

    // Refresh the search client every REFRESH_MS milliseconds to avoid the expiry of the typesense API key
    const interval = setInterval(() => {
      setupSearch(retrievingKeys, setRetrievingKeys, setTypesenseInstance, setInitialLoad);
    }, REFRESH_MS);

    return () => clearInterval(interval);
  }, [retrievingKeys, initialLoad]);

  return (
    <>
      <CustomHelmet title={"glowAI Favorites"} index={false} follow={false} />
      {initialLoad ? (
        // While the search client is being set up, show the app bar and drawer
        <Box {...outerBoxStyles}>
          <ApplicationAppBar userState={userState} />
          <ApplicationDrawer userState={userState} />
          <FeedbackSpeedDial />
        </Box>
      ) : (
        // Once the search client is set up, also show the search input and the search results grid
        <InstantSearch
          indexName={INDEX_NAME}
          searchClient={typesenseInstance.searchClient}
          future={{ preserveSharedStateOnUnmount: true }}
        >
          <Box {...outerBoxStyles}>
            <ApplicationAppBar userState={userState} />
            <ApplicationDrawer userState={userState} />
            <FeedbackSpeedDial />
            <Box {...mainBoxStyles}>
              <FavoritesGrid userState={userState} />
            </Box>
          </Box>
        </InstantSearch>
      )}
    </>
  );
}

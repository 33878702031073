import { Box, Skeleton, Stack } from "@mui/material";
import { thumbnailStyles } from "styles/thumbnail";

export function LoadingVideoPreviewPlaceholder() {
  return (
    <Stack spacing={0}>
      {/* A Box component is required to set the aspectRatio. Setting the aspectRatio for a Skeleton doesn't work */}
      <Box sx={{ width: "100%", aspectRatio: "16/9", mb: 1 }}>
        <Skeleton
          variant="rectangular"
          sx={{ ...thumbnailStyles.sx, width: "100%", height: "100%" }}
          animation="wave"
        />
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <Skeleton variant="text" animation="wave" sx={{ width: "30%" }} />
        <Skeleton variant="text" animation="wave" sx={{ width: "30%" }} />
      </Box>
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="text" animation="wave" />
    </Stack>
  );
}

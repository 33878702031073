import { analytics, auth, logEvent } from "config/firebase";
import { signOut } from "firebase/auth";
import { signInWithGooglePopup } from "./signInWithGooglePopup";

export async function signInUser(navigate, linkToAfterSignIn, functionToExecuteAfterSignIn) {
  try {
    await signInWithGooglePopup();
    if (auth.currentUser.email !== null) {
      if (functionToExecuteAfterSignIn !== null) functionToExecuteAfterSignIn();
      if (linkToAfterSignIn !== null) navigate(linkToAfterSignIn);

      // Log event
      logEvent(analytics, "user_signed_in", {
        user_uid: auth.currentUser.uid,
        user_email: auth.currentUser.email,
      });
    } else {
      signOut(auth);
      window.location.href = "/";
    }
  } catch (error) {
    console.error(error);
  }
}

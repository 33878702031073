import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useLayoutEffect, useRef } from "react";
import { useIsMobile } from "styles/breakpoint";
import theme from "styles/theme";
import { ApplyButton } from "./ApplyButton";

export function TunePopup({ title, open, handleClose, handleApply, children }) {
  const dialogContentRef = useRef(null);
  const dialogRef = useRef(null);

  // Breakpoint
  const isMobile = useIsMobile();

  useLayoutEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        if (dialogContentRef.current) {
          const { offsetHeight: contentHeight } = dialogContentRef.current;
          const newDialogHeight = contentHeight > window.innerHeight * 0.8 ? "80vh" : "auto";
          dialogContentRef.current.style.maxHeight = newDialogHeight;
        }
      }, 0); // Timeout to ensure the content is fully rendered

      // Cleanup function to clear the timeout
      return () => clearTimeout(timer);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      ref={dialogRef}
      PaperProps={{
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 0,
        },
      }}
    >
      <DialogContent
        ref={dialogContentRef}
        sx={{ overflowX: "hidden", overflowY: "auto", m: 0, pt: 0, pl: 0, pr: 0, pb: 3 }}
      >
        {/* Close icon in top right corner */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey.dark,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Tune popup content */}
        <Grid container spacing={5} sx={{ p: 2, pb: { mobile: 0, tablet: 2 }, minWidth: "300px" }}>
          {/* Title */}
          <Grid mobile={12}>
            <Typography variant="h4">{title}</Typography>
          </Grid>

          {/* Content */}
          <Grid container mobile={12}>
            {children}
          </Grid>
          {!isMobile && (
            <Grid container mobile={12} sx={{ display: "flex", justifyContent: "flex-end", mr: 1 }}>
              <ApplyButton handleApply={handleApply} />
            </Grid>
          )}
        </Grid>

        {isMobile && (
          <>
            <Box sx={{ height: "64px" }}></Box>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "64px",
                bgcolor: theme.palette.white.main,
              }}
            >
              <ApplyButton handleApply={handleApply} />
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import theme from "styles/theme";
import { calculateReadTime, formatDate } from "../utils/formatting";
import { ArticleThumbnail } from "./ArticleThumbnail";

export function HighlightArticlePreview({ data }) {
  const formattedDate = formatDate(data.date);
  const readTime = calculateReadTime(data.blocks);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blog/${data.handle}`);
  };

  return (
    <Grid container spacing={1} onClick={handleClick} sx={{ cursor: "pointer", alignItems: "center" }}>
      <Grid mobile={12} smallDesktop={7} sx={{ order: { mobile: 1, smallDesktop: 2 } }}>
        {/* Thumbnail */}
        <ArticleThumbnail image={data.image} title={data.title} />
      </Grid>
      <Grid
        container
        mobile={12}
        smallDesktop={5}
        spacing={2}
        sx={{ order: { mobile: 2, smallDesktop: 1 }, pr: { smallDesktop: 4 } }}
      >
        <Grid mobile={12}>
          <Stack direction={"row"} spacing={3} sx={{ mt: { mobile: 1, tablet: 0 } }}>
            {/* Category */}
            <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main }}>
              {data.category}
            </Typography>

            {/* Read time */}
            <Typography variant="subtitle1" sx={{ color: theme.palette.grey.dark }}>
              {readTime} MIN
            </Typography>
          </Stack>
        </Grid>

        {/* Title */}
        <Grid mobile={12}>
          <Typography variant="h1" component="div">
            {data.title}
          </Typography>
        </Grid>

        {/* Blurb */}
        <Grid mobile={12}>
          <Typography variant="body1" sx={{ color: theme.palette.grey.dark }}>
            {data.blurb}
          </Typography>
        </Grid>

        <Grid mobile={12}>
          <Stack direction="column" spacing={0}>
            {/* Author */}
            <Typography variant="subtitle1" sx={{ color: theme.palette.grey.dark }}>
              WRITTEN BY {data.author}
            </Typography>

            {/* Date */}
            <Typography variant="subtitle1" sx={{ color: theme.palette.grey.dark }}>
              {formattedDate}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { Builder, builder, withChildren } from "@builder.io/react";
import { BlogImage, Heading2, Heading3, ImageGrid, Text } from "features/blog";

builder.init(process.env.REACT_APP_BUILDER_API_KEY);

export function registerCustomBuilderComponents() {
  Builder.registerComponent(Text, {
    name: "Blog Text",
    inputs: [
      {
        name: "text",
        type: "richText",
        required: true,
      },
    ],
    models: ["blog-article", "privacy-policy", "imprint", "terms-of-service"],
  });

  Builder.registerComponent(Heading2, {
    name: "Blog H2",
    inputs: [
      {
        name: "text",
        type: "string",
        required: true,
        helperText: "Make sure that the heading includes SEO keywords",
      },
    ],
    models: ["blog-article", "privacy-policy", "imprint", "terms-of-service"],
  });

  Builder.registerComponent(Heading3, {
    name: "Blog H3",
    inputs: [
      {
        name: "text",
        type: "string",
        required: true,
        helperText: "Make sure that the heading includes SEO keywords",
      },
    ],
    models: ["blog-article", "privacy-policy", "imprint", "terms-of-service"],
  });

  Builder.registerComponent(BlogImage, {
    name: "Blog Image",
    inputs: [
      {
        name: "image",
        type: "file",
        allowedFileTypes: ["jpeg", "jpg", "png", "svg", "webp"],
        required: true,
        helperText: "Make sure that the file name of the image is descriptive and SEO-friendly",
      },
      {
        name: "alt",
        type: "string",
        required: true,
        helperText: "Make sure that the alt text is descriptive and SEO-friendly",
      },
    ],
    models: ["blog-article"],
  });

  const ImageGridWithChildren = withChildren(ImageGrid);

  Builder.registerComponent(ImageGridWithChildren, {
    name: "Blog Image Grid",
    inputs: [
      {
        name: "columnsMobile",
        type: "number",
        helperText: "The number of columns to display on mobile devices",
      },
      {
        name: "columnsTablet",
        type: "number",
        helperText: "The number of columns to display on tablet (and larger) devices",
      },
    ],
    models: ["blog-article"],
  });
}

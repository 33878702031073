import { Box, Pagination } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { LoadingThumbnailPlaceholder } from "components/thumbnail/LoadingThumbnailPlaceholder";
import { useEffect, useState } from "react";
import { thumbnailStyles } from "styles/thumbnail";
import { fetchGeneratedThumbnailsFromFirestore } from "../api/fetchGeneratedThumbnails";
import { usePromptContext } from "./PromptContext";
import { ThumbnailPopup } from "./ThumbnailPopup";

const MAX_THUMBNAILS_PER_PAGE = 40;

export function ThumbnailGrid() {
  // Context state
  const { page, setPage, thumbnailGrid, setThumbnailGrid } = usePromptContext();

  // Local state
  const [openDetailView, setOpenDetailView] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);

  // Functions to handle the thumbnail popup
  const handleOpenDetailView = (thumbnail) => {
    setSelectedThumbnail(thumbnail);
    setOpenDetailView(true);
  };

  const handleCloseDetailView = () => {
    setOpenDetailView(false);
  };

  // Fetch the generated thumbnails from firebase and set the state
  const fetchAndSetThumbnails = async () => {
    const queryData = await fetchGeneratedThumbnailsFromFirestore();
    setThumbnailGrid((prev) => ({ loadingCount: Math.max(prev.loadingCount - 1, 0), thumbnails: queryData }));
  };

  // Ensure that the firebase data is fetched when loading the page for the first time
  useEffect(() => {
    fetchAndSetThumbnails();
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ alignItems: "center", justifyContent: "left", display: "flex" }}>
        {/* Display (multiple) thumbnail loading previews if thumbnails are currently being generated  */}
        {Array.from({ length: Math.min(thumbnailGrid.loadingCount, MAX_THUMBNAILS_PER_PAGE) }, (_, i) => (
          <Grid mobile={12} tablet={6} smallDesktop={4} mediumDesktop={3} key={i}>
            <LoadingThumbnailPlaceholder />
          </Grid>
        ))}

        {/* Display generated thumbnails of current page */}
        {Object.keys(thumbnailGrid.thumbnails)
          .slice(
            (page - 1) * MAX_THUMBNAILS_PER_PAGE,
            page * MAX_THUMBNAILS_PER_PAGE - Math.min(thumbnailGrid.loadingCount, MAX_THUMBNAILS_PER_PAGE),
          )
          .map((key) => (
            <Grid
              mobile={12}
              tablet={6}
              smallDesktop={4}
              mediumDesktop={3}
              key={thumbnailGrid.thumbnails[key].thumbnail_url}
            >
              <Box
                {...thumbnailStyles}
                component="img"
                src={thumbnailGrid.thumbnails[key].thumbnail_url}
                alt={`Thumbnail ${key}`}
                onClick={() => handleOpenDetailView(thumbnailGrid.thumbnails[key])}
              />
            </Grid>
          ))}

        {/* Display the pagination component */}
        {thumbnailGrid.thumbnails.length > MAX_THUMBNAILS_PER_PAGE && (
          <Grid mobile={12} display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(thumbnailGrid.thumbnails.length / MAX_THUMBNAILS_PER_PAGE)}
              page={page}
              onChange={(event, value) => setPage(value)}
            />
          </Grid>
        )}
      </Grid>

      {/* This thumbnail popup opens if a user clicks on a thumbnail */}
      <ThumbnailPopup
        open={openDetailView}
        handleClose={handleCloseDetailView}
        selectedThumbnail={selectedThumbnail}
      />
    </>
  );
}

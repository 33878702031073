import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { analytics, logEvent } from "config/firebase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsMobile, useIsTablet } from "styles/breakpoint";
import theme from "styles/theme";

export function SearchBar() {
  // Local state
  const [userInput, setUserInput] = useState("");

  // Breakpoint
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const placeholderText = "Enter a video topic ...";
  const navigate = useNavigate();

  // On click forward to app with search query
  const handleClick = () => {
    if (userInput && userInput.trim() !== "") {
      // Log event
      logEvent(analytics, "search_landing", {
        search_query: userInput,
      });

      // Navigate to the app with the search query
      navigate(`/app/outliers?search_query=${encodeURIComponent(userInput)}`);
    }
  };

  return (
    <TextField
      autoFocus={!(isMobile || isTablet)}
      InputProps={{
        sx: {
          borderRadius: 10,
          fontSize: { mobile: 16, tablet: 20 },
          backgroundColor: theme.palette.white.main,
          paddingLeft: 2,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!userInput}
              sx={{
                ":hover": { color: userInput ? "primary.main" : "default" },
              }}
              onClick={() => handleClick()}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder={placeholderText}
      onKeyDown={(e) => {
        if (e.key === "Enter" && userInput) {
          handleClick();
        }
      }}
      onChange={(e) => setUserInput(e.target.value)}
    />
  );
}

import { Box, Chip, Tooltip, Typography } from "@mui/material";
import theme from "styles/theme";
import {
  getPerformanceScoreChipColor,
  getPerformanceScoreFontColor,
  getPerformanceScoreWording,
} from "./utils/formatting";

export function PerformanceScoreChip({ score }) {
  const roundedScore = score.toFixed(0);
  return (
    <Tooltip
      arrow
      title={
        roundedScore > 0 ? (
          <Box sx={{ textAlign: "center" }}>
            {" "}
            {/* Apply centering styles to this Box */}
            {roundedScore > 0 ? (
              <>
                glowAI Outlier Score <br /> <br />
                This score is calculated by glowAI based on various factors and is not provided by YouTube
              </>
            ) : (
              "Sorry, the glowAI Outlier Score is currently not available for this video. Please try again later."
            )}
          </Box>
        ) : (
          "Sorry, the glowAI Outlier Score is currently not available for this video. Please try again later."
        )
      }
      sx={{
        width: "100%",
        position: "absolute",
        jusitfyContent: "center",
      }}
    >
      <Chip
        size={"small"}
        label={
          <Typography variant="body3" component="div" sx={{ fontWeight: "bold" }}>
            {roundedScore > 0 ? "" + roundedScore + " - " + getPerformanceScoreWording(roundedScore) : "N/A"}
          </Typography>
        }
        variant="outlined"
        sx={{
          px: 1,
          py: 0,
          border: 0,
          borderColor: theme.palette.black.main,
          color: roundedScore > 0 ? getPerformanceScoreFontColor(roundedScore) : theme.palette.grey.dark,
          bgcolor: roundedScore > 0 ? getPerformanceScoreChipColor(roundedScore) : theme.palette.grey.light,
          mr: 1,
        }}
      />
    </Tooltip>
  );
}

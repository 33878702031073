import { Provider, auth, firebaseObserver } from "config/firebase";
import { signInWithPopup } from "firebase/auth";

Provider.setCustomParameters({ prompt: "select_account " });

auth.onAuthStateChanged(function (user) {
  firebaseObserver.publish("authStateChanged", loggedIn());
});

// Check if the user is currently logged in
function loggedIn() {
  return !!auth.currentUser;
}

// Sign in with Google popup
const signInWithGooglePopup = () => signInWithPopup(auth, Provider);

export { signInWithGooglePopup };

import { storage } from "config/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

export const uploadImagesToStorageBucket = async (images) => {
  const imageUrls = await Promise.all(
    images.map(async (image) => {
      const fileExtension = image.name.split(".").pop();
      const storageRef = ref(
        storage,
        `${process.env.REACT_APP_STORAGE_BUCKET_CUSTOMER_FEEDBACK_IMAGES}/img_${uuidv4()}.${fileExtension}`,
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          () => {},
          (error) => {
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          },
        );
      });
    }),
  );
  return imageUrls;
};

import { Box } from "@mui/material";
import { thumbnailStyles } from "styles/thumbnail";

export function ThumbnailPreview({ videoId }) {
  // Redirect to YouTube video when clicking on the thumbnail
  const redirectToYouTubeVideo = () => {
    window.open("https://www.youtube.com/watch?v=" + videoId, "_blank");
  };

  return (
    <Box
      component="img"
      src={"https://i.ytimg.com/vi/" + videoId + "/maxresdefault.jpg"}
      sx={{
        ...thumbnailStyles.sx,
        width: "100%",
        boxShadow: 1,
        transition: "all 0.1s ease",
        "&:hover": { transform: "scale3d(1.02, 1.02, 1)", cursor: "pointer" },
      }}
      alt="Example of a really good thumbnail"
      onClick={redirectToYouTubeVideo}
    />
  );
}

import { Typography } from "@mui/material";

export function Heading2({ text }) {
  return (
    <Typography variant="h2" sx={{ mt: 6 }}>
      {text}
    </Typography>
  );
}

export function Heading3({ text }) {
  return (
    <Typography variant="h3" sx={{ mt: 4 }}>
      {text}
    </Typography>
  );
}

import { createContext, useContext, useState } from "react";
import { DEFAULT_USER_FILTER_VALUES } from "../api/typesense/filters";

const OutliersContext = createContext();

export function OutliersProvider({ children }) {
  // Local state
  const [query, setQuery] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(DEFAULT_USER_FILTER_VALUES);
  const [selectedFiltersUrl, setSelectedFiltersUrl] = useState("");

  return (
    <OutliersContext.Provider
      value={{
        query,
        setQuery,
        selectedFilters,
        setSelectedFilters,
        selectedFiltersUrl,
        setSelectedFiltersUrl,
      }}
    >
      {children}
    </OutliersContext.Provider>
  );
}

export function useOutliersContext() {
  return useContext(OutliersContext);
}

import { Box } from "@mui/material";
import { FeedbackSpeedDial } from "features/feedback";
import { CustomHelmet } from "features/helmet";
import { ApplicationAppBar, ApplicationDrawer } from "features/navigation";
import {
  OutliersProvider,
  REFRESH_MS,
  SearchInput,
  SearchResultsGrid,
  searchFunction,
  setupSearch,
} from "features/outliers";
import { TypesenseManager } from "features/outliers/components/TypesenseManager";
import { useEffect, useState } from "react";
import { InstantSearch } from "react-instantsearch";
import { mainBoxStyles, outerBoxStyles } from "styles/layout";

export default function OutliersPage({ userState }) {
  // Local state
  const [typesenseInstance, setTypesenseInstance] = useState({});
  const [retrievingKeys, setRetrievingKeys] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [indexName, setIndexName] = useState(process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION);
  const [manualIndexInput, setManualIndexInput] = useState(false);

  useEffect(() => {
    // When loading the page for the first time, set up the search client
    if (initialLoad) {
      setupSearch(retrievingKeys, setRetrievingKeys, setTypesenseInstance, setInitialLoad);
    }

    // Refresh the search client every REFRESH_MS milliseconds to avoid the expiry of the typesense API key
    const interval = setInterval(() => {
      if (!manualIndexInput) setupSearch(retrievingKeys, setRetrievingKeys, setTypesenseInstance, setInitialLoad);
    }, REFRESH_MS);

    return () => clearInterval(interval);
  }, [retrievingKeys, initialLoad]);

  return (
    <>
      <CustomHelmet title={"glowAI Outlier DB"} index={true} follow={false} />
      {initialLoad ? (
        // While the search client is being set up, show the app bar and drawer
        <Box {...outerBoxStyles}>
          <ApplicationAppBar userState={userState} />
          <ApplicationDrawer userState={userState} />
          <FeedbackSpeedDial />
        </Box>
      ) : (
        // Once the search client is set up, also show the search input and the search results grid
        <InstantSearch
          indexName={indexName ? indexName : process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION}
          searchClient={typesenseInstance.searchClient}
          searchFunction={(helper) => searchFunction(helper)}
          future={{ preserveSharedStateOnUnmount: true }}
        >
          <OutliersProvider>
            <Box {...outerBoxStyles}>
              <ApplicationAppBar userState={userState}>
                <SearchInput />
              </ApplicationAppBar>
              <ApplicationDrawer userState={userState} />
              <FeedbackSpeedDial />
              <Box {...mainBoxStyles}>
                {/* Add Developer Feature for Selecting the Typesense Outliers Collection */}
                {userState.developer && (
                  <TypesenseManager
                    setIndexName={setIndexName}
                    setTypesenseInstance={setTypesenseInstance}
                    setManualIndexInput={setManualIndexInput}
                  />
                )}
                <SearchResultsGrid
                  userState={userState}
                  indexName={indexName ? indexName : process.env.REACT_APP_TYPESENSE_OUTLIERS_COLLECTION}
                />
              </Box>
            </Box>
          </OutliersProvider>
        </InstantSearch>
      )}
    </>
  );
}

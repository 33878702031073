import LogoutIcon from "@mui/icons-material/Logout";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { analytics, auth, logEvent } from "config/firebase";

export function UserMenu({ anchorEl, handleClose }) {
  const handleLogout = () => {
    // Log event
    logEvent(analytics, "user_signed_out", {
      user_uid: auth.currentUser.uid,
      user_email: auth.currentUser.email,
    });

    auth
      .signOut() // Call the signOut function when Log Out is clicked
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* Stripe currently not required */}
        {/* <MenuItem
          onClick={handleClose}
          component="a"
          href="https://billing.stripe.com/p/login/14kg1HbnE2OLahqcMM"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemIcon>
            <PaymentIcon fontSize="small" />
          </ListItemIcon>
          Manage Subscription
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2">Log Out</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

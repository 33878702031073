import { Box, Drawer, List, Toolbar } from "@mui/material";
import { useIsDesktop, useIsMobile, useIsTablet } from "styles/breakpoint";
import theme from "styles/theme";

export const DRAWER_HEIGHT = 64;

export function ResponsiveDrawer({ children }) {
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  var drawerWidth = "auto";
  if (isDesktop) {
    drawerWidth = 240;
  } else if (isTablet) {
    drawerWidth = 80;
  }
  return isMobile ? (
    <>
      <Drawer
        variant="permanent"
        anchor="bottom"
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            height: DRAWER_HEIGHT,
            boxSizing: "border-box",
            justifyContent: "center",
          },
        }}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-around">
          {children}
        </Box>
      </Drawer>
      {/* Hacky trick to get rid of the transparent space below the drawer in Chrome on iPhone 15 */}
      <Box
        sx={{
          position: "fixed",
          bottom: "-100px",
          left: 0,
          right: 0,
          height: "100px",
          color: theme.palette.white.main,
          backgroundColor: theme.palette.white.main,
          zIndex: theme.zIndex.drawer - 1,
        }}
      />
    </>
  ) : (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          borderWidth: 0,
        },
      }}
    >
      <Toolbar />
      <List sx={{ pl: 2, pr: 0, pt: 3 }}>{children}</List>
    </Drawer>
  );
}

import { Box, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { LoadingThumbnailPlaceholder } from "components/thumbnail/LoadingThumbnailPlaceholder";
import { useState } from "react";
import theme from "styles/theme";
import { promptToThumbnail } from "../api/cloudFunctions";
import { AddThumbnailPlaceholder } from "./AddThumbnailPlaceholder";
import { useIdeationContext } from "./IdeationContext";

export function Concept({ concept }) {
  // Context state
  const { imageModelSetting } = useIdeationContext();

  // Local state
  const [thumbnailUrls, setThumbnailsUrls] = useState([]);
  const [loadingCount, setLoadingCount] = useState(0);

  // Local state update functions
  const incrementLoadingCount = () => {
    setLoadingCount((prev) => prev + 1);
  };

  const decrementLoadingCount = () => {
    setLoadingCount((prev) => prev - 1);
  };

  // Generate an additional thumbnail for the concept
  const handleClick = async () => {
    incrementLoadingCount();
    try {
      const response = await promptToThumbnail({ prompt: concept.image_prompt, model: imageModelSetting });
      setThumbnailsUrls((prev) => [...prev, response.data.thumbnail_url]);
      decrementLoadingCount();
    } catch (error) {
      console.log(error);
      decrementLoadingCount();
    }
  };

  return (
    <Box
      sx={{
        borderWidth: 1,
        borderColor: theme.palette.grey.main,
        borderStyle: "solid",
        borderRadius: 5,
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        {/* Desiplay the description of the concept */}
        <Grid item mobile={12}>
          {concept.description ? (
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {concept.description}
            </Typography>
          ) : (
            <Skeleton variant="text" animation="wave" sx={{ width: "100%" }} />
          )}
        </Grid>

        {/* Display the image prompt of the concept */}
        <Grid item mobile={12}>
          {concept.image_prompt ? (
            <Typography variant="body2">{concept.image_prompt}</Typography>
          ) : (
            <Skeleton variant="text" animation="wave" sx={{ width: "100%" }} />
          )}
        </Grid>

        <Grid item mobile={12}>
          <Divider sx={{ borderWidth: 0.5, borderColor: theme.palette.grey.main, borderStyle: "solid" }} />
        </Grid>

        {/* Display the generated thumbnails for this concept */}
        {thumbnailUrls.map((thumbnailUrl) => (
          <Grid item mobile={12} tablet={6} smallDesktop={4} largeDesktop={3} key={thumbnailUrl}>
            <Box
              component="img"
              sx={{ maxWidth: "100%", borderRadius: 4 }}
              src={thumbnailUrl}
              alt={`Thumbnail ${thumbnailUrl}`}
            />
          </Grid>
        ))}

        {/* Display (multiple) thumbnail loading placeholders while the thumbnails are generated  */}
        {Array.from({ length: loadingCount }, (_, i) => (
          <Grid item mobile={12} tablet={6} smallDesktop={4} largeDesktop={3} key={i}>
            <LoadingThumbnailPlaceholder />
          </Grid>
        ))}

        {/* Display an option to add a new thumbnail for this concept */}
        <Grid item mobile={12} tablet={6} smallDesktop={4} largeDesktop={3}>
          <AddThumbnailPlaceholder handleClick={handleClick} disabled={concept.image_prompt ? false : true} />
        </Grid>
      </Grid>
    </Box>
  );
}

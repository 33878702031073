import { builder } from "@builder.io/react";

// Fetch all available blog articles from Builder.io
export async function fetchAllArticlesFromBuilder() {
  return builder.getAll("blog-article").then((fetchedArticles) => {
    const sortedArticles = fetchedArticles.sort((a, b) => new Date(b.data.date) - new Date(a.data.date));
    return sortedArticles;
  });
}

// Fetch a single blog article from Builder.io by its handle
export async function fetchArticleFromBuilder(handle) {
  return builder
    .get("blog-article", {
      query: {
        "data.handle": handle,
      },
    })
    .then((fetchedArticle) => {
      return fetchedArticle;
    });
}

// Fetch all articles from Builder.io except the one with the given handle
export async function fetchOtherArticlesFromBuilder(handle) {
  const allArticles = await fetchAllArticlesFromBuilder();
  if (allArticles && allArticles.length > 0) {
    const otherArticles = allArticles.filter((article) => article.data.handle !== handle);
    shuffleArray(otherArticles);
    return otherArticles;
  } else {
    return [];
  }
}

// Randomize array in-place using Durstenfeld shuffle algorithm
function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

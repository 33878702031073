import { Box } from "@mui/system";
import { Article, fetchArticleFromBuilder } from "features/blog";
import { Footer } from "features/footer";
import { CustomHelmet } from "features/helmet";
import { LandingAppBar } from "features/navigation";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { landingContentStyles, landingMainStyles } from "styles/layout";

export default function BlogArticlePage() {
  // Local state
  const [article, setArticle] = useState(null);

  // Router
  const { handle } = useParams();
  const navigate = useNavigate();

  // When the component loads, fetch article from builder
  useEffect(() => {
    const fetchArticle = async () => {
      const fetchedArticle = await fetchArticleFromBuilder(handle);
      if (!fetchedArticle) {
        navigate("/blog");
      } else {
        setArticle(fetchedArticle);
      }
    };

    fetchArticle();
  }, [handle, navigate]);

  return (
    <>
      <CustomHelmet
        title={article?.data?.title}
        description={article?.data?.blurb}
        image={article?.data?.image}
        type={"article"}
      />
      <Box {...landingMainStyles}>
        <LandingAppBar componentBackground="white" />
        <Box {...landingContentStyles}>
          <Article article={article} />
        </Box>
        <Footer />
      </Box>
    </>
  );
}

import { Button } from "@mui/material";
import theme from "styles/theme";

export function ApplyButton({ handleApply }) {
  return (
    <Button
      onClick={handleApply}
      sx={{
        borderRadius: 50,
        zIndex: theme.zIndex.drawer + 1,
        bgcolor: theme.palette.primary.main,
        color: theme.palette.white.main,
        textTransform: "none",
        border: 0,
        "&:hover": {
          bgcolor: "rgba(48, 111, 219, 0.8)",
          border: 0,
        },
      }}
    >
      Apply
    </Button>
  );
}

import { AppBar, Avatar, Box, Stack, Toolbar, Typography } from "@mui/material";
import { default as LogoLarge } from "assets/logos/glowAI_icon_color_and_logo_black.svg";
import { default as LogoSmall } from "assets/logos/glowAI_icon_white_and_background_color.svg";
import { SignInButton } from "components/appBar/SignInButton";
import { auth } from "config/firebase";
import { useState } from "react";
import { useIsDesktop } from "styles/breakpoint";
import theme from "styles/theme";
import { UserMenu } from "./UserMenu";

export const APP_BAR_HEIGHT = "64px";

export function ApplicationAppBar({ userState, children }) {
  // Breakpoint
  const isDesktop = useIsDesktop();

  // User menu open and close logic
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  // Check if user is authenticated
  const isAuthenticated = userState.authenticated;

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: theme.palette.white.main,
          boxShadow: "none",
          width: "100%",
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ alignItems: "center", width: "100%", display: "flex", minHeight: APP_BAR_HEIGHT }}>
          <Stack
            direction={"row"}
            width={"100%"}
            spacing={1}
            alignItems={"center"}
            alignContent={"center"}
            justifyContent={"space-between"}
          >
            {/* Logo on the left side of the app bar */}
            <Box
              href="/app"
              sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={(event) => {
                event.preventDefault();
                // Force page reload to bring user from search page to overview page and trigger default search
                window.location.href = "/";
              }}
            >
              <Box
                component={"img"}
                src={isDesktop ? LogoLarge : LogoSmall}
                alt="glowAI"
                sx={{ height: { mobile: 30, tablet: 40, smallDesktop: 25 }, alignSelf: "center" }}
              />
            </Box>

            {/* Passed in children (i.e. search bar) in the middle of the app bar */}
            {children}

            {/* User icon or sign in button on the right side of the app bar */}
            {isAuthenticated ? (
              <Avatar
                onClick={handleClick}
                sx={{
                  height: { mobile: 30, tablet: 40 },
                  width: { mobile: 30, tablet: 40 },
                  boxShadow: 0,
                  color: theme.palette.white.main,
                  bgcolor: "primary.main",
                  cursor: "pointer",
                  transition: "box-shadow 0.3s ease-in-out",
                  "&:hover": {
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <Typography variant="button">{auth.currentUser.displayName[0]}</Typography>
              </Avatar>
            ) : (
              <SignInButton isWhiteBackground={true} adjustSize={true} openPopupInsteadOfLink={true} />
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      {/* User menu which opens by click on user icon */}
      <UserMenu anchorEl={anchorEl} handleClose={handleCloseUserMenu} />
    </>
  );
}

import { getVideoScores, liveQuery } from "../cloudFunctions";
import { USER_FILTER_VALUES } from "./filters";

// If less than then this amount of hits is returned from typesense, a live search is triggered
export const LIVE_SEARCH_THRESHOLD = 10;

// Fetch new videos via the YouTube API
export async function triggerLiveSearch(
  typesenseHits,
  query,
  selectedFilters,
  setRawCombinedHits,
  setLiveSearchState,
  setIsLiveSearchProgress,
  setIsLiveSearch,
) {
  // Set the live search state
  setIsLiveSearch(true);

  // Update the the current LiveSearchState
  setLiveSearchState((prev) => ({
    ...prev,
    query: query,
  }));

  // Call cloud function
  const result = await liveQuery({
    query: query,
    published_at_filter: convertPublishedAtFilterValueToYouTubeFormat(selectedFilters),
  });

  const newVideos = result.data;

  // If no new videos are found, the live search is finished
  if (newVideos.length === 0) {
    setIsLiveSearch(false);
    setLiveSearchState((prev) => ({ ...prev, combinedHits: typesenseHits }));
    return;
  }

  // Combine typesense hits with the new videos and remove duplicates
  const combinedHits = [...typesenseHits, ...newVideos].filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  // Update the combined hits so they are immediately shown in the frontend (some of them won't have a score yet)
  setRawCombinedHits(combinedHits);

  // Also update the live search state to save the new videos since the combined hits can be overwritten
  setLiveSearchState((prev) => ({ ...prev, combinedHits: combinedHits }));

  // Check if there are new videos without a score
  const videosWithoutScore = newVideos.filter((video) => video.med_view_score === null);

  // For videos that don't have a score yet they need to be calculated
  fetchVideoScores(videosWithoutScore, setRawCombinedHits, setLiveSearchState, setIsLiveSearchProgress);
}

// Fetch the video scores for the videos that don't have a score yet
async function fetchVideoScores(videos, setRawCombinedHits, setLiveSearchState, setIsLiveSearchProgress) {
  // Group the videos by channel to reduce the amount of API calls
  const channelGroups = groupVideosByChannel(videos);

  // Calculate the progress multiplier (80% of the progress is for fetching the video scores and the multiplier
  // corresponds to the total number of videos for which scores need to be calculated)
  const progressMulitplier = 80 / getTotalVideoCount(channelGroups);

  // Fetch the video scores for each channel
  Object.entries(channelGroups).forEach(([channelId, { videoIds, videoCount }]) => {
    // Call the cloud function to get the video scores
    getVideoScores({ channel_id: channelId, video_ids: videoIds })
      .then((res) => {
        // Update the search state to save the new scores since the combined hits can be overwritten
        setLiveSearchState((prev) => {
          // First update the scores
          const updatedCombinedHits = updateVideoScoresOfCombinedHits(prev.combinedHits, res.data, videoIds);
          // Update the combined hits with the new scores (so they are shown in the frontend)
          setRawCombinedHits(updatedCombinedHits);
          return { ...prev, combinedHits: updatedCombinedHits };
        });

        // Update the live search progress accordingly
        setIsLiveSearchProgress((prev) => ({
          ...prev,
          fetched: prev.fetched + Math.ceil(progressMulitplier * videoCount),
        }));
      })
      .catch(console.error);
  });
}

// Check if there is a published_at filter set by the user, because this parameter can also be set in the YT API call
function convertPublishedAtFilterValueToYouTubeFormat(selectedFilters) {
  const publishedAt = selectedFilters.published_at;
  const publishedAtFilter =
    publishedAt !== "All"
      ? "" + Number(USER_FILTER_VALUES.published_at.values[publishedAt].slice(1)).toFixed(0)
      : "-1";
  return publishedAtFilter;
}

// Group an array of video objects by channel (returns: { channelId: { videoIds: [], videoCount: <#videosOfChannel> } )
function groupVideosByChannel(videos) {
  return videos.reduce((acc, video) => {
    if (!acc[video.channel_id]) {
      acc[video.channel_id] = { videoIds: [], videoCount: 0 };
    }
    acc[video.channel_id].videoIds.push(video.id);
    acc[video.channel_id].videoCount = video.channel_video_count;
    return acc;
  }, {});
}

// Get the total number of videos for which scores need to be calculated (this includes all the other videos on the
// channel as they are required for calculating the score)
function getTotalVideoCount(channelGroups) {
  return Object.values(channelGroups).reduce((acc, channel) => acc + channel.videoCount, 0);
}

// Update the video scores of the combined hits
function updateVideoScoresOfCombinedHits(combinedHits, scores, videoIds) {
  return combinedHits.map((video) => {
    if (videoIds.includes(video.id)) {
      return { ...video, ...scores[video.id] };
    }
    return video;
  });
}

import { functions } from "config/firebase";
import { httpsCallable } from "firebase/functions";

export const videoSummaryToConcepts = httpsCallable(functions, "video-summary-to-concepts", {
  region: process.env.REACT_APP_CLOUD_FUNCTION_REGION,
});

export const promptToThumbnail = httpsCallable(functions, "prompt-to-thumbnail", {
  region: process.env.REACT_APP_CLOUD_FUNCTION_REGION,
});
